import { Formik } from "formik";
import { useDispatch } from "react-redux";

import FormDisplay from "./FormDisplay";
import { useAlert, useAxios } from "../../hooks";
import { validationSchema } from "./passportObject";

export default function Form({
  formData,
  PassportDetails,
  setPassportDetails,
}) {
  const axios = useAxios({
    baseURL: "passport",
    contentType: "multipart/form-data",
  });
  const { alert } = useAlert();
  const dispatch = useDispatch();

  const formatDate = (date = new Date()) => {
    let year, month, day;

    year = date.getFullYear();
    month = date.getMonth() + 1;
    day = date.getDate();

    month = month.toString().padStart(2, 0);
    day = day.toString().padStart(2, 0);

    return `${day}/${month}/${year}`;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        axios({
          url:
            "/fetch-passport?file_number=" +
            formData.fileNumber +
            "&dob=" +
            formatDate(formData.dob),
          method: "GET",
        })
          .then(async (res) => {
            setSubmitting(false);
            if (res.status) {
              dispatch(alert({ message: "Details fetched!", type: "success" }));
              setPassportDetails(res.data.result);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>
          <FormDisplay
            formikProps={formikProps}
            PassportDetails={PassportDetails}
            setPassportDetails={setPassportDetails}
          />
        </form>
      )}
    </Formik>
  );
}
