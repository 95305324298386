import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Grid,
  Button,
  IconButton,
  Typography,
  DialogActions,
  DialogContent,
  Dialog as MUIDialog,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import { Cancel } from "@mui/icons-material";

import FilterDataForm from "./FilterDataForm";
import { useAxios, useComponent } from "../../../../hooks";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "800px" },
}));

export default function FilterDataDialog({
  apiList,
  dialogOpen,
  setApiList,
  setDialogOpen,
  setLogsRequest,
}) {
  const axios = useAxios();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { alert } = useComponent();

  const [loading, setLoading] = useState(false);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  var [filterData, setFilterData] = useState({
    status: "",
    start_time: "",
    end_time: "",
    api_name: "",
    created_by: "",
  });

  return (
    <React.Fragment>
      <MUIDialog
        open={dialogOpen}
        classes={{ paper: classes.paper }}
        onClose={handleClose}
      >
        <Grid
          container
          spacing={0}
          style={{
            borderBottom: "1px solid #cbcbcb",
          }}
        >
          <Grid style={{ padding: "16px 24px" }} item xs={8}>
            <Typography variant="h5">Filter Data</Typography>
          </Grid>

          <Grid item xs={4}>
            <IconButton
              disableRipple
              style={{ float: "right", padding: "16px 24px" }}
              onClick={handleClose}
            >
              <Cancel />
            </IconButton>
          </Grid>
        </Grid>

        <React.Fragment>
          <DialogContent
            style={{
              paddingTop: "10px",
            }}
          >
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography
                  variant="caption1"
                  style={{ color: "rgb(128, 128, 128)" }}
                >
                  Apply filter to the table data.
                </Typography>
              </Grid>

              <FilterDataForm
                state={state}
                apiList={apiList}
                setState={setState}
                filterData={filterData}
                setApiList={setApiList}
                setFilterData={setFilterData}
              />
            </Grid>
          </DialogContent>

          <DialogActions style={{ padding: "12px 24px 24px" }}>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs></Grid>

              <Grid item style={{ textAlign: "end" }}>
                <Button
                  onClick={clearFilter}
                  variant="outlined"
                  color="primary"
                >
                  Clear Filter
                </Button>
              </Grid>

              <Grid item style={{ textAlign: "end" }}>
                <LoadingButton
                  onClick={applyFilter}
                  variant="contained"
                  color="primary"
                >
                  Apply Filter
                </LoadingButton>
              </Grid>
            </Grid>
          </DialogActions>
        </React.Fragment>
      </MUIDialog>
    </React.Fragment>
  );

  function handleClose() {
    setDialogOpen(false);
  }

  function applyFilter() {
    setLoading(true);
    axios({
      url:
        "/requested-logs-list?created_by=" +
        filterData.created_by +
        "&status=" +
        filterData.status +
        "&start_date=" +
        filterData.start_time +
        "&end_date=" +
        filterData.end_time +
        "&api_name=" +
        filterData.api_name,
      method: "GET",
    }).then((response) => {
      setLoading(false);
      if (response.status) {
        if (response.data.length > 0) {
          alert.success(response.message.displayMessage);
          setLogsRequest && setLogsRequest(response.data);
          setDialogOpen(false);
        } else {
          dispatch(alert.error("No Logs Requested"));
          setDialogOpen(false);
        }
      }
    });
  }

  function clearFilter() {
    setFilterData({
      status: "",
      start_time: "",
      end_time: "",
      api_name: "",
      created_by: "",
    });

    axios({
      url: "/requested-logs-list?created_by=",
      method: "GET",
      data: {},
    }).then((response) => {
      if (response.status) {
        setFilterData(response);
      }
    });
  }
}
