import React, { useState } from "react";

import Form from "./Form";
import { PageHeader } from "../../components";
import { drivingLicenseObject } from "./drivingLicenseObject";

export default function DrivingLicenseDetails() {
  const [drivingLiciense, setDrivingLicense] = useState([]);
  const [formData, setFormData] = useState(drivingLicenseObject);

  return (
    <React.Fragment>
      <PageHeader pageName="Driving License Details" />
      <Form
        formData={formData}
        setFormData={setFormData}
        drivingLiciense={drivingLiciense}
        setDrivingLicense={setDrivingLicense}
      />
    </React.Fragment>
  );
}
