import { useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import DateFilter from "./DateFilter";
import UsageDetails from "./UsageDetails";
import { PageHeader, Loader } from "../../components";
import { useAlert, useAxios, useComponent } from "../../hooks";

export default function User() {
  const axios = useAxios();
  const { alert } = useAlert();
  const dispatch = useDispatch();
  const { loader } = useComponent();

  const [userData, setUserData] = useState([]);
  const [memberList, setMemberList] = useState([]);

  var [filterData, setFilterData] = useState({
    start_date: "",
    end_date: "",
    reqMember: [],
  });

  useEffect(() => {
    loader.start(1);
    axios({
      url: "/usage",
      method: "POST",
      data: {
        start_date: filterData.start_date,
        end_date: filterData.end_date,
        req_members: filterData.reqMember,
      },
    })
      .then(async (res) => {
        if (res.status) {
          dispatch(
            alert({
              message: "Usage fetched Successfully!",
              type: "success",
            })
          );
          setUserData(res.data);
          loader.apiComplete(1);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    axios({
      url: "/members",
    })
      .then((response) => {
        if (response.status) {
          setMemberList(response.data);
          loader.apiComplete(1);
        }
      })
      .catch((err) => console.log(err));
  }, [axios, loader]);

  return (
    <Loader>
      <PageHeader
        pageName="Usage Details"
        buttons={[
          <DateFilter
            filterData={filterData}
            memberList={memberList}
            setFilterData={setFilterData}
            setMemberList={setMemberList}
          />,
        ]}
      />

      <UsageDetails userData={userData} />
    </Loader>
  );
}
