import * as Yup from "yup";

export const voterObject = {
  epicNumber: "",
};

export function objectFromResponseData(responseData) {
  return {};
}

export const validationSchema = Yup.object().shape({
  epicNumber: Yup.string().required("This field is required."),
});
