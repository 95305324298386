import * as Yup from "yup";
import { Formik } from "formik";
import { Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Fragment, useState } from "react";

import { useAxios } from "../../hooks";
import DownloadDialog from "./Download";
import Label from "./../../components/Label";
import Paper from "./../../components/Paper";
import { ComponentHeader, PageHeader, TextField } from "../../components";

export default function DigiLocker() {
  const axios = useAxios();

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    full_name: "",
    mobile: "",
    email: "",
  });

  const [downloadDialog, setDownloadDialog] = useState({
    popUp: false,
    clientId: "",
    url: "",
  });

  return (
    <Fragment>
      <PageHeader pageName="Digi Locker" />

      <Paper>
        <Formik
          initialValues={formData}
          validationSchema={validationSchema}
          onSubmit={(formData) => {
            setLoading(true);

            axios({
              url: "/digilocker/session/initiate",
              method: "POST",
              data: {
                full_name: formData.full_name,
                mobile: formData.mobile,
                email: formData.email,
              },
              disableRedirect: true,
            })
              .then((response) => {
                if (response.status) {
                  setDownloadDialog({
                    popUp: true,
                    clientId: response.data.client_id,
                    url: response.data.url,
                  });
                }
                setLoading(false);
              })
              .catch((error) => {
                setLoading(false);
              });
          }}
        >
          {(formikProps) => {
            return (
              <Fragment>
                <Grid item xs={12}>
                  <ComponentHeader
                    title="Digi Locker"
                    buttons={[
                      <LoadingButton
                        variant="contained"
                        disabled={!formikProps.dirty || !formikProps.isValid}
                        onClick={formikProps.handleSubmit}
                        loading={loading}
                      >
                        Submit
                      </LoadingButton>,
                    ]}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Label
                    required
                    title="Full Name"
                    name="full_name"
                    formikProps={formikProps}
                  />

                  <TextField
                    label="Full Name"
                    name="full_name"
                    formikProps={formikProps}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Label
                    required
                    name="email"
                    title="Email"
                    formikProps={formikProps}
                  />

                  <TextField
                    label="Email"
                    name="email"
                    formikProps={formikProps}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Label
                    required
                    title="Mobile"
                    name="mobile"
                    formikProps={formikProps}
                  />

                  <TextField
                    name="mobile"
                    label="Mobile"
                    formikProps={formikProps}
                  />
                </Grid>

                <DownloadDialog
                  formikProps={formikProps}
                  setFormData={setFormData}
                  downloadDialog={downloadDialog}
                  setDownloadDialog={setDownloadDialog}
                />
              </Fragment>
            );
          }}
        </Formik>
      </Paper>
    </Fragment>
  );
}

const validationSchema = Yup.object().shape({
  full_name: Yup.string()
    .matches(
      /^[a-zA-Z\s]+$/,
      "Full Name must contain only alphabetic characters"
    )
    .required("Full Name  is required."),
  mobile: Yup.string()
    .max(10, "Mobile must be at most 10 Digits")
    .required("Mobile  is required."),
  email: Yup.string().required("Email  is required."),
});
