import styled from "styled-components";

import { Fragment, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Grid, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import "./index.css";

import { useAxios } from "../../hooks";
import EmailIDForm from "./EmailIdForm";
import StudentIDForm from "./StudentIDCard";
import Paper from "./../../components/Paper";
import { PageHeader } from "../../components";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const VerifiedIcon = styled(DoneIcon)`
  color: green;
  vertical-align: middle;
  font-size: 30px;
`;
const UnverifiedIcon = styled(CloseIcon)`
  color: red;
  vertical-align: middle;
  font-size: 30px;
`;

export default function Email() {
  const axios = useAxios({
    customBaseURL: "https://dev.backend-kyc-dashboard.metawing.ai",
  });

  const [steps, setSteps] = useState({
    email: "",
    responseMessage: "",
    idCard: "",
    step: 0,
    status: false,
    emailStatus: false,
    idStatus: false,
  });

  function handleSkipStep(e) {
    if (steps.step !== 2) {
      setSteps((prev) => ({
        ...prev,
        email: "",
        responseMessage: "",
        idCard: "",
        step: steps.step !== 2 ? steps.step + 1 : 0,
        status: false,
        emailStatus: steps.step == 1 ? prev.emailStatus : false,
        idStatus: false,
      }));
    } else {
      setSteps({
        email: "",
        responseMessage: "",
        idCard: "",
        step: 0,
        status: false,
        emailStatus: false,
        idStatus: false,
      });
    }
  }

  function handleBackStep(e) {
    setSteps((prev) => ({
      ...prev,
      email: "",
      responseMessage: "",
      idCard: "",
      step: steps.step !== 0 ? steps.step - 1 : 0,
      status: false,
      emailStatus: false,
      idStatus: false,
    }));
  }

  return (
    <Wrapper>
      <PageHeader pageName="Student Verification" />
      <Grid
        item
        container
        justifyContent={"space-between"}
        m={"20px 0px 10px 0px"}
      >
        <Button
          variant="contained"
          disabled={Boolean(steps.step === 0)}
          onClick={handleBackStep}
        >
          <ArrowBackIcon /> Back
        </Button>

        <Button variant="contained" onClick={handleSkipStep}>
          {steps.step !== 2 ? (
            <Fragment>
              Skip <ArrowForwardIcon />
            </Fragment>
          ) : (
            <Fragment>
              <RestartAltIcon />
              Try Again
            </Fragment>
          )}
        </Button>
      </Grid>

      {Boolean(steps.step === 0) && (
        <EmailIDForm steps={steps} setSteps={setSteps} />
      )}

      {Boolean(steps.step === 1) && (
        <StudentIDForm steps={steps} setSteps={setSteps} />
      )}

      {Boolean(steps.responseMessage && steps.step === 1) && (
        <Grid
          item
          container
          justifyContent={"center"}
          position={"absolute"}
          top={steps.step === 0 ? "57%" : "70%"}
        >
          <Typography variant="h4">
            {steps.status ? <VerifiedIcon /> : <UnverifiedIcon />}
            {steps.responseMessage}
          </Typography>
        </Grid>
      )}

      {Boolean(steps.step === 2) && (
        <Paper>
          <Grid item container justifyContent={"center"}>
            <Typography variant="h3" fontWeight={600}>
              Verification Status
            </Typography>
          </Grid>

          <Grid item container justifyContent={"center"} m={"25px 0px"}>
            {steps.idStatus || steps.emailStatus ? (
              <VerifiedIcon
                style={{
                  fontSize: "60px",
                  border: "3px solid green",
                  borderRadius: "50px",
                }}
              />
            ) : (
              <UnverifiedIcon
                style={{
                  fontSize: "60px",
                  border: "3px solid red",
                  borderRadius: "50px",
                }}
              />
            )}
          </Grid>

          <Grid item container spacing={2}>
            <Grid
              item
              container
              flexDirection={"column-reverse"}
              alignContent={"end"}
              xs={4}
            >
              <Typography variant="h6" fontWeight={700}>
                Email Verification
              </Typography>
              <Typography variant="h6" textAlign={"center"} fontWeight={400}>
                {steps.emailStatus ? (
                  <VerifiedComponent />
                ) : (
                  <UnverifiedComponent />
                )}
              </Typography>
            </Grid>

            <Grid item container alignContent={"center"} xs={4}>
              <div
                style={{
                  width: "100%",
                  background: "red",
                  height: "2px",
                  backgroundColor: " rgb(255 0 0 / 56%)",
                  backgroundImage: `linear-gradient(90deg , ${
                    steps.emailStatus ? "#82ff77" : "rgb(255 0 0 / 56%)"
                  } 49%, ${
                    steps.idStatus ? "#82ff77" : "rgb(255 0 0 / 56%)"
                  } 48%)`,
                }}
              ></div>
            </Grid>

            <Grid
              item
              container
              flexDirection={"column-reverse"}
              alignContent={"start"}
              xs={4}
            >
              <Typography variant="h6" fontWeight={700}>
                ID Verification{" "}
              </Typography>
              <Typography variant="h6" textAlign={"center"} fontWeight={400}>
                {steps.idStatus ? (
                  <VerifiedComponent />
                ) : (
                  <UnverifiedComponent />
                )}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Wrapper>
  );
}

function VerifiedComponent() {
  return (
    <Fragment>
      <VerifiedIcon
        style={{
          border: "3px solid green",
          borderRadius: "5px",
          marginBottom: "10px",
        }}
      />
    </Fragment>
  );
}

function UnverifiedComponent() {
  return (
    <Fragment>
      <UnverifiedIcon
        style={{
          border: "3px solid red",
          borderRadius: "5px",
          marginBottom: "10px",
        }}
      />
    </Fragment>
  );
}
