import React, { useState } from "react";

import Form from "./Form";
import { PageHeader } from "../../components";
import SignatureForm from "./signature components/Form";
import { fileUploadsObject } from "./fileUploadsObject";
import { signatureObject } from "./signature components/signatureObject";

export default function FileUpload() {
  const [fileUpload, setfileUpload] = useState({});
  const [signatureUpload, setSignatureUpload] = useState({});
  const [formData, setFormData] = useState(fileUploadsObject);
  const [signFormData, setSignFormData] = useState(signatureObject);
  const [dialogState, setDialogState] = useState(false);

  return (
    <React.Fragment>
      <PageHeader pageName="File Upload" />

      <Form
        formData={formData}
        setFormData={setFormData}
        fileUpload={fileUpload}
        setfileUpload={setfileUpload}
      />

      <SignatureForm
        signFormData={signFormData}
        setSignFormData={setSignFormData}
        dialogState={dialogState}
        setDialogState={setDialogState}
        signatureUpload={signatureUpload}
        setSignatureUpload={setSignatureUpload}
      />
    </React.Fragment>
  );
}
