import { useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";

import Form from "./Form";
import { PageHeader, Loader } from "../../components";
import { aadharDetailsObject } from "./aadharDetailsObject";
import { useAlert, useAxios, useComponent } from "../../hooks";

export default function AadharDetails() {
  const axios = useAxios();
  const { alert } = useAlert();
  const dispatch = useDispatch();
  const { loader } = useComponent();

  const [show, setShow] = useState(true);
  const [hide, setHide] = useState(false);
  const [captcha, setCaptcha] = useState([]);
  const [aadharDetails, setAadharDetails] = useState([]);
  const [formData, setFormData] = useState(aadharDetailsObject);

  useEffect(() => {
    loader.start(1);
    axios({
      url: "/captcha",
    })
      .then(async (res) => {
        if (res.status) {
          dispatch(
            alert({ message: "Captcha fetched Successfully!", type: "success" })
          );
          setCaptcha(res.data);
          loader.apiComplete(1);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [axios, loader]);

  return (
    <Loader>
      <PageHeader pageName="Aadhar Details" />

      <Form
        show={show}
        hide={hide}
        setShow={setShow}
        setHide={setHide}
        captcha={captcha}
        formData={formData}
        setCaptcha={setCaptcha}
        setFormData={setFormData}
        aadharDetails={aadharDetails}
        setAadharDetails={setAadharDetails}
      />
    </Loader>
  );
}
