import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import React, { useRef, useEffect } from "react";
import { FileUpload } from "../../../components";
import { uploadToS3 } from "../../../utils";

function ImgCapture({ bioFormikProps, onSubmit, loading }) {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const uploadedFiles = useRef([]);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "environment" },
        audio: false,
      });
      videoRef.current.srcObject = stream;
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const takePhoto = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (video && canvas) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const imageDataURL = canvas.toDataURL("image/png");

      let folderPath = "documents/" + new Date().getTime() + "_";

      const uploadBucket = {
        name: "dc-chat-media",
        region: "ap-south-1",
        identityPoolID: "ap-south-1:16fa1fcf-7cdd-4593-9ee0-6f621526defc",
      };

      const fileName = folderPath + "file.png"; // Changed file extension to .png

      // Convert base64 data URL to a Blob object
      const byteString = atob(imageDataURL.split(",")[1]);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([arrayBuffer], { type: "image/png" });

      uploadToS3({
        bucket: uploadBucket,
        filePath: fileName,
        file: blob,
      }).then((response) => {
        if (response.status) {
          uploadedFiles.current.push({
            name: "file.png",
            // size: file.size,
            lastModified: new Date(),
            link:
              "https://" +
              uploadBucket.name +
              ".s3.ap-south-1.amazonaws.com/" +
              fileName,
          });

          bioFormikProps.setValues((prevValues) => ({
            ...prevValues,
            aadharQRCodeImg:
              "https://" +
              uploadBucket.name +
              ".s3.ap-south-1.amazonaws.com/" +
              fileName,
          }));

          if (onSubmit) {
            onSubmit();
          }
        }
      });
    }
  };

  useEffect(() => {
    startCamera();
  }, []);

  useEffect(() => {
    const handleOrientation = () => {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      if (video && canvas) {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
      }
    };

    window.addEventListener("resize", handleOrientation);
    window.addEventListener("orientationchange", handleOrientation);

    return () => {
      window.removeEventListener("resize", handleOrientation);
      window.removeEventListener("orientationchange", handleOrientation);
    };
  }, []);
  return (
    <Grid container display={"flex"} flexDirection={"column"}>
      <Typography textAlign={"center"} fontWeight={"bold"}>
        Click Face Image Photo
      </Typography>
      <Grid item position={"relative"} left={"17%"} width={"70%"}>
        <video
          id="video"
          width="100%"
          ref={videoRef}
          muted
          autoPlay
          playsInline
        ></video>
      </Grid>
      <Grid item xs={12} mt={2} display={"flex"} justifyContent={"center"}>
        <LoadingButton
          variant="contained"
          id="click-photo"
          type="button"
          onClick={takePhoto}
          loading={loading}
        >
          Click Photo
        </LoadingButton>
      </Grid>
      <canvas
        id="canvas"
        width="320"
        height="240"
        ref={canvasRef}
        style={{ display: "none" }}
      ></canvas>
    </Grid>
  );
}

export default ImgCapture;
