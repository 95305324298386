import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import React, { useEffect, useState, Fragment } from "react";

import { PageHeader } from "../../components";
import { useAxios, useComponent } from "../../hooks";
import Table from "./logs request table components/Table";
import GenrateLogRequest from "./logs request table components/GenrateLogRequest";

export default function LogsRequest() {
  const axios = useAxios();
  const dispatch = useDispatch();
  const { loader } = useComponent();

  const [apiList, setApiList] = useState([]);
  const [logsRequest, setLogsRequest] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [generateLogId, setGenerateLogId] = useState("");

  useEffect(() => {
    loader.start(1);
    axios({
      url: "/api-name",
      method: "GET",
    }).then((response) => {
      if (response.status) {
        setApiList(response.data);
        loader.apiComplete();
      }
    });
  }, [axios, loader]);

  useEffect(() => {
    loader.start(1);
    axios({
      url: "/requested-logs-list",
      method: "GET",
    }).then((response) => {
      if (response.status) {
        setLogsRequest(response.data);
        loader.apiComplete();
      }
    });
  }, [dispatch, axios, loader, generateLogId]);

  return (
    <Fragment>
      <PageHeader pageName="Log Reports" buttons={[<CreateButton />]} />

      <Table
        apiList={apiList}
        setApiList={setApiList}
        logsRequest={logsRequest}
        generateLogId={generateLogId}
        setGenerateLogId={setGenerateLogId}
      />

      <GenrateLogRequest
        apiList={apiList}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        setGenerateLogId={setGenerateLogId}
      />
    </Fragment>
  );

  function handleClickOpen() {
    setDialogOpen(true);
  }

  function CreateButton() {
    return (
      <Button color="primary" variant="contained" onClick={handleClickOpen}>
        Request Log
      </Button>
    );
  }
}
