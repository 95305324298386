import React from "react";
import { get } from "lodash";
import { DateRange } from "react-date-range";

import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { Grid, MenuItem, Typography } from "@mui/material";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { TextField } from "../../components";

export default function FilterDataForm({
  state,
  apiList,
  setState,
  memberList,
  filterData,
  setFilterData,
}) {
  function handleChange(item) {
    setState([item.selection]);

    const timestamp1 = new Date(item.selection.startDate).getTime();
    const timestamp2 = new Date(item.selection.endDate).getTime();

    setFilterData((prev) => ({
      ...prev,
      start_time: timestamp1,
      end_time: timestamp2,
    }));
  }

  function handleMemberChange(event) {
    const {
      target: { value },
    } = event;

    setFilterData((prevVal) => ({
      ...prevVal,
      user_id: setValuesOfDropDown(prevVal.name),
    }));

    function setValuesOfDropDown(values) {
      values = typeof value === "string" ? value.split(",") : value;
      return values;
    }
  }

  function renderingValue(value, array) {
    var arr = [];
    if (value.length > 0) {
      value.map((item) => arr.push(array.find((x) => x.id === item).label));
    }

    return arr;
  }

  function handleApiName(e) {
    setFilterData((prev) => ({
      ...prev,
      api_name: e.target.value,
    }));
  }

  function handleStatus(e) {
    setFilterData((prev) => ({
      ...prev,
      status: e.target.value,
    }));
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Filter by date</Typography>
              </Grid>

              <Grid item xs={12}>
                <DateRange
                  onChange={(item) => handleChange(item)}
                  ranges={state}
                  maxDate={new Date()}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Filter by API Name and Status.
                </Typography>
              </Grid>

              <Grid item xs={12} pt="5px">
                <TextField
                  select
                  name={"api_name"}
                  label="API Name"
                  value={filterData.api_name}
                  onChange={handleApiName}
                >
                  {apiList.map((item) => (
                    <MenuItem key={item.key} value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} pt="5px">
                <TextField
                  fullWidth
                  select
                  name="user_id"
                  label="Member"
                  value={filterData.user_id}
                  onChange={handleMemberChange}
                  SelectProps={{
                    renderValue: (selected) =>
                      renderingValue(selected, memberList).join(","),
                    multiple: true,
                    value: get(filterData, "user_id", []),
                    onChange: handleMemberChange,
                  }}
                >
                  {memberList.map((item, id) => (
                    <MenuItem key={id} value={item.id}>
                      <Checkbox
                        checked={
                          get(filterData, "user_id", "").indexOf(item.id) > -1
                        }
                      />
                      <ListItemText primary={item.label} />
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name={"status"}
                  label="API Status"
                  value={filterData.status}
                  onChange={handleStatus}
                  select
                >
                  <MenuItem value="success">Success</MenuItem>
                  <MenuItem value="fail">Fail</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
