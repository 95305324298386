import { Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { ComponentHeader, Paper, TextField } from "../../../components";

export default function FormDisplay({ formikProps, loading }) {
  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader title={"Verify Email"} />
      </Grid>

      <Grid item container justifyContent={"space-between"}>
        <Grid item xs={6}>
          <TextField label={"Email"} name={"email"} formikProps={formikProps} />
        </Grid>

        <Grid item>
          <LoadingButton
            loading={loading}
            onClick={formikProps.handleSubmit}
            disabled={!formikProps.dirty || !formikProps.isValid}
            variant="outlined"
          >
            Verify Email
          </LoadingButton>
        </Grid>
      </Grid>
    </Paper>
  );
}
