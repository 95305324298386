import React from "react";
import { Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import ShowFileResponse from "./show response components/ShowFileResponse";
import { ComponentHeader, Paper, FileUpload } from "../../components";

export default function FormDisplay({
  formikProps,
  fileUpload,
  setDialogState,
  dialogState,
}) {
  return (
    <React.Fragment>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader title={"Verify Checkbox "} subtitle="" />
        </Grid>

        <Grid item xs={6}>
          <FileUpload
            required
            name="file"
            label="File URL"
            formikProps={formikProps}
            allowedExtension={".jpg,.jpeg,.png"}
          />
        </Grid>

        <Grid item xs></Grid>
        <Grid item alignSelf="center">
          <LoadingButton
            type="submit"
            variant="contained"
            fullWidth
            onClick={formikProps.handleSubmit}
            disabled={!formikProps.dirty || !formikProps.isValid}
            loading={formikProps.isSubmitting}
          >
            Upload Image
          </LoadingButton>
        </Grid>

        <ShowFileResponse
          fileUpload={fileUpload}
          setDialogState={setDialogState}
          dialogState={dialogState}
        />
      </Paper>
    </React.Fragment>
  );
}
