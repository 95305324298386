import React from "react";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import { CheckCircle } from "@mui/icons-material";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Grid, Typography } from "@mui/material";
import VerificationComponent from "./VerificationComponent";

const labelsMapping = {
  //   request_id: "Request ID",
  aadhar_check: "Aadhaar verified",
  flight_check: "Ticket verified",
  face_check: "Face verified",
};

const StageConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#096929",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#096929",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const StageStepRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#096929",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#096929",
    zIndex: 1,
    fontSize: 22,
  },
  "& .QontoStepIcon-circle": {
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor: "#096929",
  },
}));

function StageIcon({ active, completed, className }) {
  const iconColor = completed ? "#096929" : "red";

  return (
    <StageStepRoot ownerState={{ active }} className={className}>
      {completed ? (
        <CheckCircle
          className="QontoStepIcon-completedIcon"
          style={{ color: iconColor }}
        />
      ) : (
        <div
          className="QontoStepIcon-circle"
          style={{ backgroundColor: iconColor }}
        />
      )}
    </StageStepRoot>
  );
}

export default function StageStepper({ data }) {
  const filteredKeys = Object.keys(data).filter((key) => key !== "request_id");

  const steps = filteredKeys.map((key) => labelsMapping[key]);

  return (
    <Box sx={{ width: "100%" }} mt={10}>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          fontWeight={"bold"}
          textAlign={"center"}
          pb={15}
        >
          Passengers Detail Status
        </Typography>
      </Grid>

      <VerificationComponent data={data} />

      <Stepper connector={<StageConnector />} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label} completed={data[Object.keys(data)[index]]}>
            <StepLabel StepIconComponent={StageIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
