import React, { useState } from "react";
import { FilterList } from "@mui/icons-material";
import { IconButton, Tooltip, Badge } from "@mui/material";

import FilterDataDialog from "./FilterDataDialog";

export default function DateFilter({
  filterData,
  setFilterData,
  setUserData,
  memberList,
  setMemberList,
}) {
  const [dialogOpen, setDialogOpen] = useState(false);

  function searchQueryCount() {
    let count = 0;

    for (let query in filterData) {
      if (!!filterData[query]) {
        count++;
      }
    }

    return count;
  }

  function handleClick() {
    setDialogOpen(true);
  }

  return (
    <React.Fragment>
      <Tooltip title={"Filter Data"}>
        <IconButton onClick={handleClick}>
          <Badge
            variant="dot"
            badgeContent={searchQueryCount()}
            color="secondary"
          >
            <FilterList />
          </Badge>
        </IconButton>
      </Tooltip>

      <FilterDataDialog
        dialogOpen={dialogOpen}
        memberList={memberList}
        filterData={filterData}
        setUserData={setUserData}
        setFilterData={setFilterData}
        setMemberList={setMemberList}
        setDialogOpen={setDialogOpen}
      />
    </React.Fragment>
  );
}
