import { Grid } from "@mui/material";
import React, { Fragment, useState } from "react";
import { QrReader } from "react-qr-reader";
import styled from "styled-components";

const Root = styled("Grid")(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    marginLeft: "15%",
  },
  [theme.breakpoints.only("sm")]: {
    marginLeft: "20%",
  },
  [theme.breakpoints.only("xm")]: {
    marginLeft: "27%",
  },
  [theme.breakpoints.only("md")]: {
    marginLeft: "35%",
  },
  [theme.breakpoints.only("lg")]: {
    marginLeft: "35%",
  },
  [theme.breakpoints.only("xl")]: {
    marginLeft: "35%",
  },
}));

const QRCodeReader = ({ bioFormikProps, OnSubmit }) => {
  return (
    <Root
      item
      sm={8}
      xm={5}
      md={4}
      lg={3}
      xl={3}
      textAlign="center"
      justifyContent={"center"}
      style={{
        height: "320px",
        width: "320px",
        // marginLeft: "35%",
      }}
    >
      <QrReader
        constraints={{ facingMode: "environment" }}
        onResult={(result, error) => {
          if (result) {
            bioFormikProps.setValues((prevVal) => ({
              ...prevVal,
              aadharQRCode: result?.text,
            }));

            OnSubmit();
          }
        }}
      />
    </Root>
  );
};

export default QRCodeReader;
