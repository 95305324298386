import { get } from "lodash";
import React from "react";

import Checkbox from "@mui/material/Checkbox";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import ListItemText from "@mui/material/ListItemText";
import { Grid, TextField, MenuItem } from "@mui/material";

export default function DateFilterData({
  date,
  setDate,
  filterData,
  memberList,
  setFilterData,
}) {
  function handleChange(event) {
    const {
      target: { value },
    } = event;

    setFilterData((prevVal) => ({
      ...prevVal,
      reqMember: setValuesOfDropDown(prevVal.name),
    }));

    function setValuesOfDropDown(values) {
      values = typeof value === "string" ? value.split(",") : value;
      return values;
    }
  }

  function renderingValue(value, array) {
    var arr = [];
    if (value.length > 0) {
      value.map((item) => arr.push(array.find((x) => x.id === item).label));
    }

    return arr;
  }

  function handleDateChange(item) {
    setDate([item.selection]);

    const timestamp1 = new Date(item.selection.startDate).getTime();
    const timestamp2 = new Date(item.selection.endDate).getTime();

    setFilterData((prev) => ({
      ...prev,
      start_time: timestamp1,
      end_time: timestamp2,
    }));
  }

  return (
    <React.Fragment>
      <Grid container spacing={2} direction="row">
        <Grid item xs={6}>
          <DateRange
            onChange={(item) => handleDateChange(item)}
            ranges={date}
            maxDate={new Date()}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            select
            name="reqMember"
            label="Member"
            value={filterData.reqMember}
            onChange={handleChange}
            SelectProps={{
              renderValue: (selected) =>
                renderingValue(selected, memberList).join(","),
              multiple: true,
              value: get(filterData, "reqMember", []),
              onChange: handleChange,
            }}
          >
            {memberList.map((item, id) => (
              <MenuItem key={id} value={item.id}>
                <Checkbox
                  checked={
                    get(filterData, "reqMember", "").indexOf(item.id) > -1
                  }
                />
                <ListItemText primary={item.label} />
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
