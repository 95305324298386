import React, { useState } from "react";

import Form from "./Form";
import { PageHeader } from "../../components";

import { registrationCertificateObject } from "./registrationCertificateObject";

export default function RegisterCertificateDetails() {
  const [registerDetails, setRegisterDetails] = useState([]);
  const [formData, setFormData] = useState(registrationCertificateObject);

  return (
    <React.Fragment>
      <PageHeader pageName="Registration Certificate Details" />

      <Form
        formData={formData}
        setFormData={setFormData}
        registerDetails={registerDetails}
        setRegisterDetails={setRegisterDetails}
      />
    </React.Fragment>
  );
}
