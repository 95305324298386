import { Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import { Fragment, useState } from "react";
import { Grid, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

import { useAxios } from "../../hooks";
import { Paper, TextField } from "../../components";

function StudentIDForm({ steps, setSteps }) {
  const axios = useAxios();

  const [loading, setLoading] = useState(false);

  return (
    <Formik
      initialValues={{ IdCard: "" }}
      onSubmit={(formData) => {
        setLoading(true);
        const data = new FormData();
        data.append("image", formData.IdCard);

        axios({
          url: "/students/id/ocr",
          method: "POST",
          data: data,
          disableRedirect: true,
        }).then((response) => {
          setLoading(false);

          if (response?.status) {
            setSteps((prev) => ({
              ...prev,
              idCard: response?.data,
              responseMessage: response?.message?.displayMessage,
              step: 2,
              status: response?.status,
              idStatus: response?.status,
            }));
          } else {
            setSteps((prev) => ({
              ...prev,
              responseMessage: response?.displayMessage,
              step: 2,
              status: response?.status,
              idStatus: response?.status,
            }));
          }
        });
      }}
    >
      {(formikProps) => {
        function handleFileChange(e) {
          formikProps.setFieldValue("IdCard", e.target?.files[0]);
        }

        return (
          <Paper>
            <Grid item container m={1} justifyContent={"center"}>
              <Typography variant="h3" fontWeight={600}>
                Fill Student ID
              </Typography>
            </Grid>
            <Grid item container m={2} justifyContent={"center"}>
              <Fragment>
                <div className="hero-heading">
                  <Typography variant="h5"></Typography>
                </div>

                <div className="hero-heading margin">
                  <Typography fontSize={"20px"}></Typography>
                </div>
                <div className="aadhar-card">
                  <div className="header">
                    <div className="emblem-div"></div>
                    <div className="tri-color">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ fontWeight: "600" }}> College :</span>
                        {steps.idCard?.college ? (
                          <span> &nbsp; {steps.idCard?.college}</span>
                        ) : (
                          <div className="orange"></div>
                        )}
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ fontWeight: "600" }}> University :</span>
                        {steps.idCard?.university ? (
                          <span> &nbsp; {steps.idCard?.university}</span>
                        ) : (
                          <div className="green"></div>
                        )}
                      </div>
                    </div>
                    <div className="emblem-div"></div>
                  </div>

                  <div className="middle">
                    <div className="user-avatar">
                      <PersonIcon sx={{ fontSize: "70px", color: "#8EC5FC" }} />
                    </div>

                    <div className="user-details">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ fontWeight: "600" }}> Name:</span>
                        {steps.idCard?.student_name ? (
                          <span>&nbsp; {steps.idCard?.student_name}</span>
                        ) : (
                          <div className="name"></div>
                        )}
                      </div>
                      <div style={{ display: "flex", alignItems: "baseline" }}>
                        <span style={{ fontWeight: "600" }}>DOB:</span>
                        {steps.idCard?.date_of_birth ? (
                          <span>&nbsp; {steps.idCard?.date_of_birth}</span>
                        ) : (
                          <div className="dob"></div>
                        )}
                      </div>
                      <div style={{ display: "flex", alignItems: "baseline" }}>
                        <span style={{ fontWeight: "600" }}>Course:</span>
                        {steps.idCard?.course ? (
                          <span> &nbsp; {steps.idCard?.course}</span>
                        ) : (
                          <div className="session"></div>
                        )}
                      </div>

                      <Grid item container xs={12} mt={2} spacing={2}>
                        <Grid item xs={8}>
                          <TextField
                            type="file"
                            name="IdCard"
                            inputProps={{ accept: "image/png, image/jpeg" }}
                            onChange={handleFileChange}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <LoadingButton
                            variant="contained"
                            size="large"
                            fullWidth
                            loading={loading}
                            onClick={formikProps.handleSubmit}
                            sx={{
                              height: "100%",
                              borderRadius: "10px",
                              "&:hover": { borderRadius: "10px" },
                            }}
                          >
                            Verify
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </Fragment>
            </Grid>
          </Paper>
        );
      }}
    </Formik>
  );
}

export default StudentIDForm;
