/*
Contains all the routes pertaining to Dashboard Layout
Use the following structure: 
export const {name}Route = {
  ID: "",
  path: "/",
  element: "",
  index: true, (if the path is default for some folder)
  disableAuth: true, (if the page should always be in the dashboard)
}
*/

import pages from "../../constants/pages";
import Logs from "../../pages/logs components/Logs";
import User from "../../pages/user components/User";
import Match from "../../pages/match components/Match";
import OcrDetails from "../../pages/ocr components/OcrDetails";
import PanDetails from "../../pages/pan components/PanDetails";
import VoterDetails from "../../pages/voter components/VoterDetails";
import AadharDetails from "../../pages/aadhar components/AadharDetails";
import FileUpload from "../../pages/file upload components/FileUpload";
import LogsRequest from "../../pages/logs request components/LogsRequestList";
import PassportDetails from "../../pages/passport components/PassportPage";
import PassportFetchDetails from "../../pages/passport fetch components/PassportFetchDetails";
import DrivingLicenseDetails from "../../pages/driving license components/DrivingLicenseDetails";
import BulkProcessList from "../../pages/bulk process components/bulk process list components/BulkProcessList";
import RegisterCertificateDetails from "../../pages/registration certificate component/RegisterCertificateDetails";
import BulkProcessDetails from "../../pages/bulk process components/bulk process details components/BulkProcessDetails";
import StudentOcrDetails from "../../pages/student ocr components/OcrDetails";
import DigiLocker from "../../pages/digi locker components/DigiLocker";
import DigiYatra from "../../pages/digi yatra components/DigiYatra";
import PhoneVerification from "../../pages/phone verification components/Phone";

// import async from "./Async";
//For async const Component = async(() => import ("../component location"));
import PhoneVerification01 from "./../../pages/phone verification with consent components/Phone";
import Email from "../../pages/email components/Email";
import Home from "../../pages/home components/Home";

//Main Module Components

const dashboardLayoutRoutes = {
  HomeRoute: {
    ID: pages.home.ID,
    path: pages.home.route,
    element: Home,
  },

  passportRoute: {
    ID: pages.passportPage.ID,
    path: pages.passportPage.route,
    element: PassportDetails,
  },
  passportFetchRoute: {
    ID: pages.passportFetchPage.ID,
    path: pages.passportFetchPage.route,
    element: PassportFetchDetails,
  },

  registerCertificateRoute: {
    ID: pages.registerCertificatePage.ID,
    path: pages.registerCertificatePage.route,
    element: RegisterCertificateDetails,
  },
  drivingLicenseRoute: {
    ID: pages.drivingLicensePage.ID,
    path: pages.drivingLicensePage.route,
    element: DrivingLicenseDetails,
  },
  voterRoute: {
    ID: pages.voterPage.ID,
    path: pages.voterPage.route,
    element: VoterDetails,
  },
  panRoute: {
    ID: pages.panPage.ID,
    path: pages.panPage.route,
    element: PanDetails,
  },
  ocrRoute: {
    ID: pages.ocrPage.ID,
    path: pages.ocrPage.route,
    element: OcrDetails,
  },
  StudentOcrRoute: {
    ID: pages.StudentOcrPage.ID,
    path: pages.StudentOcrPage.route,
    element: StudentOcrDetails,
  },
  aadharRoute: {
    ID: pages.aadharPage.ID,
    path: pages.aadharPage.route,
    element: AadharDetails,
  },
  userRoute: {
    ID: pages.userPage.ID,
    path: pages.userPage.route,
    element: User,
  },
  bulkProcessRoute: {
    ID: pages.bulkRcProcessPage.ID,
    path: pages.bulkRcProcessPage.route,
    element: BulkProcessList,
  },
  bulkProcessCreateRoute: {
    ID: pages.bulkProcessCreatePage.ID,
    path: pages.bulkProcessCreatePage.route,
    element: BulkProcessDetails,
  },
  logsRoute: {
    ID: pages.logsPage.ID,
    path: pages.logsPage.route,
    element: Logs,
  },
  logsRequestRoute: {
    ID: pages.logsRequestPage.ID,
    path: pages.logsRequestPage.route,
    element: LogsRequest,
  },
  matchRoute: {
    ID: pages.matchPage.ID,
    path: pages.matchPage.route,
    element: Match,
  },

  fileUploadRoute: {
    ID: pages.fileUploadPage.ID,
    path: pages.fileUploadPage.route,
    element: FileUpload,
  },

  digiLockerRoute: {
    ID: pages.digiLockerPage.ID,
    path: pages.digiLockerPage.route,
    element: DigiLocker,
  },

  digiYatraRoute: {
    ID: pages.digiYatraPage.ID,
    path: pages.digiYatraPage.route,
    element: DigiYatra,
  },

  phoneVerfiyRoute: {
    ID: pages.phoneVerifyPage.ID,
    path: pages.phoneVerifyPage.route,
    element: PhoneVerification,
  },
  phoneVerfiy01Route: {
    ID: pages.phoneVerify01Page.ID,
    path: pages.phoneVerify01Page.route,
    element: PhoneVerification01,
  },

  emailRoute: {
    ID: pages.emailPage.ID,
    path: pages.emailPage.route,
    element: Email,
  },
};

export default dashboardLayoutRoutes;
