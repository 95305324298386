const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    xm: 700,
    md: 960,
    lg: 1280,
    xl: 1440,
  },
};

export default breakpoints;
