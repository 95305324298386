import * as Yup from "yup";

export const signatureObject = {
  file: "",
  fileName: "",
};

export const validationSchema = Yup.object().shape({
  file: Yup.string().required("This field is required."),
});
