import React from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";

import FormDisplay from "./FormDisplay";
import { useAlert, useAxios } from "../../hooks";
import { validationSchema } from "./voterObject";

export default function Form({ formData, voterDetails, setVoterDetails }) {
  const axios = useAxios({});
  const { alert } = useAlert();
  const dispatch = useDispatch();

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        const data = new FormData();
        data.append("epic_no", formData.epicNumber);
        axios({
          url: "/voter-details/" + formData.epicNumber,
          method: "GET",
        })
          .then(async (res) => {
            setSubmitting(false);
            if (res.status) {
              dispatch(alert({ message: "Details fetched!", type: "success" }));
              setVoterDetails(res.data);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>
          <FormDisplay
            formikProps={formikProps}
            voterDetails={voterDetails}
            setVoterDetails={setVoterDetails}
          />
        </form>
      )}
    </Formik>
  );
}
