const pages = {
  //Dashboard Module

  home: {
    ID: "home",
    route: "/",
  },

  insights: {
    ID: "insights",
    route: "/insights",
  },

  signIn: {
    ID: "sign-in",
    route: "/sign-in",
  },

  userPage: {
    ID: "user-page",
    route: "/user",
  },

  passportPage: {
    ID: "passport-page",
    route: "/passport",
  },

  passportFetchPage: {
    ID: "passport-fetch-page",
    route: "/passport-fetch",
  },

  fileUploadPage: {
    ID: "file-upload",
    route: "/file-upload",
  },

  registerCertificatePage: {
    ID: "register-certificate-page",
    route: "/register-certificate",
  },

  drivingLicensePage: {
    ID: "driving-license-page",
    route: "/driving-license",
  },

  voterPage: {
    ID: "voter-page",
    route: "/voter",
  },

  panPage: {
    ID: "pan-page",
    route: "/pan",
  },

  ocrPage: {
    ID: "ocr-page",
    route: "/ocr",
  },

  StudentOcrPage: {
    ID: "student-ocr-page",
    route: "/student-ocr",
  },
  aadharPage: {
    ID: "aadhar-page",
    route: "/aadhar",
  },

  bulkRcProcessPage: {
    ID: "bulk-process-page",
    route: "/bulk-process",
  },

  bulkProcessCreatePage: {
    ID: "bulk-process-create-page",
    route: "/bulk-process-create",
  },

  logsPage: {
    ID: "logs-page",
    route: "/logs-list",
  },

  logsRequestPage: {
    ID: "logs-request-page",
    route: "/logs-request",
  },

  matchPage: {
    ID: "match-page",
    route: "/match-name",
  },
  digiLockerPage: {
    ID: "digi-locker",
    route: "/digi-locker",
  },
  digiYatraPage: {
    ID: "digi-yatra",
    route: "/digi-yatra",
  },
  phoneVerifyPage: {
    ID: "phone-verify",
    route: "/phone-verify",
  },

  phoneVerify01Page: {
    ID: "phone-verify-1",
    route: "/phone-verify-1",
  },

  emailPage: {
    ID: "email",
    route: "/student-verification",
  },
};

export default pages;
