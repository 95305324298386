import React from "react";
import { CSVLink } from "react-csv";
import { Grid, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import PanTableList from "./pan table components/PanTableList";
import { ComponentHeader, Paper, TextField } from "../../components";

export default function FormDisplay({
  panDetails,
  formikProps,
  setPanDetails,
}) {
  return (
    <React.Fragment>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader title={"Pan Details"} buttons={[<SampleFile />]} />
        </Grid>

        <Grid item xs={6}>
          <TextField
            required
            name="pan"
            label="Pan Number"
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs></Grid>
        <Grid item alignSelf="center">
          <LoadingButton
            type="submit"
            variant="contained"
            fullWidth
            onClick={formikProps.handleSubmit}
            disabled={!formikProps.dirty || !formikProps.isValid}
            loading={formikProps.isSubmitting}
          >
            Fetch Driving License Details
          </LoadingButton>
        </Grid>
      </Paper>

      <PanTableList panDetails={panDetails} setPanDetails={setPanDetails} />
    </React.Fragment>
  );

  function SampleFile() {
    const csvData = [
      {
        pan_number: "fgips5421l",
        pan_type: "Person",
        pan_status: "VALID",
        last_name: "SRIVASTAVA",
        first_name: "SHUBHAM",
        pan_holder_title: "Shri",
        pan_last_updated: "19/03/2022",
        name_on_card: "SHUBHAM SRIVASTAVA",
        aadhaar_seeding_status: "Y",
      },
    ];

    return (
      <Button variant="outlined">
        <CSVLink data={csvData} style={{ textDecoration: "none" }}>
          {" "}
          Download Sample File
        </CSVLink>
      </Button>
    );
  }
}
