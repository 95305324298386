import { Formik } from "formik";
import { useDispatch } from "react-redux";

import FormDisplay from "./FormDisplay";
import { useAlert, useAxios } from "../../../hooks";
import { validationSchema } from "./signatureObject";

export default function SignatureForm({
  signFormData,
  signatureUpload,
  setSignatureUpload,
  dialogState,
  setDialogState,
}) {
  const axios = useAxios();
  const { alert } = useAlert();
  const dispatch = useDispatch();

  return (
    <Formik
      enableReinitialize
      initialValues={signFormData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        const data = new FormData();
        data.append("file", formData.file);
        axios({
          url: "/signature-classification",
          method: "POST",
          data: { url: formData.file },
          disableRedirect: true,
        })
          .then(async (res) => {
            setSubmitting(false);
            if (res.status) {
              dispatch(alert({ message: "Details fetched!", type: "success" }));
              setSignatureUpload(res.data);
              setDialogState(true);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>
          <FormDisplay
            formikProps={formikProps}
            signatureUpload={signatureUpload}
            dialogState={dialogState}
            setDialogState={setDialogState}
            setSignatureUpload={setSignatureUpload}
          />
        </form>
      )}
    </Formik>
  );
}
