import React, { useState } from "react";

import Form from "./Form";
import { formDataObject } from "./formDataObject";
import { PageHeader } from "../../../components";

export default function BulkProcessDetails() {
  const [editState, setEditState] = useState(true);
  const [formData, setFormData] = useState(formDataObject);

  return (
    <React.Fragment>
      <PageHeader pageName="Create Bulk Process" />

      <Form
        formData={formData}
        setFormData={setFormData}
        editState={editState}
        setEditState={setEditState}
      />
    </React.Fragment>
  );
}
