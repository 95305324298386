import { Fragment, useState } from "react";
import { Formik } from "formik";

import Loader from "../../../components/Loader";
import { useAxios, useComponent } from "../../../hooks";
import { useDispatch } from "react-redux";
import QRCodeReader from "./ImgCapture";

function From({ setProcessData, setProcessStatus }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { alert } = useComponent();
  const axios = useAxios({
    customBaseURL: "https://uat.component.dolphinchat.ai",
    disableAuthToken: false,
  });
  const [formData, setFormData] = useState({ aadharQRCode: "" });

  return (
    <Fragment>
      <Formik
        initialValues={formData}
        enableReinitialize
        onSubmit={(formData) => {
          setLoading(true);
          axios({
            url: "aadhar-verification/qr-data",
            method: "POST",
            data: {
              qr_data: formData.aadharQRCode,
            },
            disableRedirect: true,
            disableError: true,
          })
            .then((response) => {
              setLoading(false);
              if (response.status) {
                setProcessData((prev) => ({
                  ...prev,
                  request_id: response?.data?.qr_data.request_id,
                  aadharCardDetails: response?.data?.passenger_list
                    ? response.data.passenger_list
                    : [],
                }));

                setProcessStatus((prev) => ({ ...prev, qrImage: false }));

                dispatch(alert.success(response?.displayMessage));
              } else {
                dispatch(alert.error("Data Not Found"));
              }
            })
            .catch((error) => {
              setLoading(false);

              dispatch(alert.error("error"));

              setProcessStatus((prev) => ({
                ...prev,
                qrImage: true,
              }));
            });
        }}
      >
        {(bioFormikProps) => {
          return (
            <Loader>
              <QRCodeReader
                bioFormikProps={bioFormikProps}
                OnSubmit={bioFormikProps.handleSubmit}
              />
            </Loader>
          );
        }}
      </Formik>
    </Fragment>
  );
}

export default From;
