import React, { useState, useEffect } from "react";

import { DataTable } from "../../../components";

function getBase64Img(b64text) {
  return "data:image/png;base64," + b64text;
}

export default function Table({ aadharDetails }) {
  const [displayData, setDisplayData] = useState();

  useEffect(() => {
    let data = [];
    for (const key in aadharDetails) {
      if (key === "doc_face") {
        let base64img = getBase64Img(aadharDetails[key]);
        data.push({
          key: key,
          value: (
            <a href={base64img} download>
              image.png
            </a>
          ),
        });
      } else {
        data.push({ key: key, value: aadharDetails[key] });
      }
    }
    setDisplayData(data);
  }, []);

  const options = {
    sortOrder: {
      name: "created_at",
      direction: "desc",
      rowsPerPage: 50,
      rowsPerPageOptions: [100],
      filter: false,
      viewColumns: false,
    },
  };

  const columns = [
    {
      name: "key",
      label: "Key",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "value",
      label: "Value",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
  ];

  return (
    <React.Fragment>
      <DataTable columns={columns} options={options} data={displayData} />
    </React.Fragment>
  );
}
