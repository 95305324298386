import React from "react";
import { Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { ComponentHeader, Paper, FileUpload } from "../../../components";
import ShowResponse from "../show response components/ShowResponse";

export default function FormDisplay({
  formikProps,
  dialogState,
  setDialogState,
  signatureUpload,
}) {
  return (
    <React.Fragment>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader title={"Verify Signature"} subtitle="" />
        </Grid>

        <Grid item xs={6}>
          <FileUpload
            required
            name="file"
            label="File URL"
            formikProps={formikProps}
            allowedExtension={".jpg,.jpeg,.png"}
          />
        </Grid>

        <Grid item xs></Grid>
        <Grid item alignSelf="center">
          <LoadingButton
            type="submit"
            variant="contained"
            fullWidth
            onClick={formikProps.handleSubmit}
            disabled={!formikProps.dirty || !formikProps.isValid}
            loading={formikProps.isSubmitting}
          >
            Upload Signature
          </LoadingButton>
        </Grid>
      </Paper>

      <ShowResponse
        signatureUpload={signatureUpload}
        setDialogState={setDialogState}
        dialogState={dialogState}
      />
    </React.Fragment>
  );
}
