import React from "react";
import { CSVLink } from "react-csv";
import { Grid, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { ComponentHeader, Paper, TextField } from "../../components";
import RegistrationTableList from "./registration certificate table component/RegistrationTableList";

export default function FormDisplay({
  formikProps,
  registerDetails,
  setRegisterDetails,
}) {
  return (
    <React.Fragment>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader
            buttons={[<SampleFile />]}
            title={"Registration Certificate Details"}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            required
            name="registrationCertificateNumber"
            label="Registration Certificate Number"
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs></Grid>

        <Grid item alignSelf="center">
          <LoadingButton
            type="submit"
            variant="contained"
            fullWidth
            onClick={formikProps.handleSubmit}
            disabled={!formikProps.dirty || !formikProps.isValid}
            loading={formikProps.isSubmitting}
          >
            Fetch RC Details
          </LoadingButton>
        </Grid>
      </Paper>

      <RegistrationTableList
        registerDetails={registerDetails}
        setRegisterDetails={setRegisterDetails}
      />
    </React.Fragment>
  );

  function SampleFile() {
    const csvData = [
      {
        vehicleMake: "MARUTI SUZUKI INDIA LTD",
        vehicleModel: "SWIFT VXI AGS",
        vehicleCategory: "LMV",
        vehicleClassNumber: "4",
        status: "OK",
        rc_f_name: null,
        rc_noc_details: null,
        rc_regn_no: "DL7CU2665",
        rc_vh_class_desc: "Motor Car",
        rc_chasi_no: "MBHCZCB3SNFA42885",
        rc_eng_no: "K12NP1254135",
        rc_maker_desc: "MARUTI SUZUKI INDIA LTD",
        rc_maker_model: "SWIFT VXI AGS",
        rc_color: "SILKY SILVER",
        rc_fuel_desc: "PETROL",
        rc_norms_desc: "BHARAT STAGE VI",
        rc_body_type_desc: "RIGID (PASSENGER CAR)",
        rc_owner_sr: "1",
        rc_owner_name: "SHANU SHAWAN JHA",
        rc_status: "ACTIVE",
        rc_status_as_on: "21/12/2022",
        rc_registered_at: "MAYUR VIHAR, DELHI",
        rc_regn_dt: "20/07/2022",
        rc_manu_month_yr: "06/2022",
        rc_fit_upto: "19/07/2037",
        rc_tax_upto: "",
        rc_insurance_comp: "TATA AIG GENERAL INSURANCE CO. LTD.",
        rc_insurance_upto: "05/07/2025",
        rc_insurance_policy_no: "m10495447",
        rc_financer: "HDFC BANK LIMITED",
        rc_present_address: "13 B ASOLA FATEH PUR BERI,SOUTH 110074",
        rc_permanent_address: "13 B ASOLA FATEH PUR BERI,SOUTH 110074",
        rc_cubic_cap: "1197.0",
        rc_gvw: "1335",
        rc_unld_wt: "890",
        rc_vch_catg: "LMV",
        rc_no_cyl: "4",
        rc_seat_cap: "5",
        rc_sleeper_cap: "0",
        rc_stand_cap: "0",
        rc_wheelbase: "2450",
        rc_pucc_no: "Newv4",
        rc_pucc_upto: "19/07/2023",
        rc_blacklist_status: "",
        rc_permit_issue_dt: "NA",
        rc_permit_no: "",
        rc_permit_type: "",
        rc_permit_valid_from: "NA",
        rc_permit_valid_upto: "NA",
        rc_np_no: "",
        rc_np_issued_by: "",
      },
    ];

    return (
      <Button variant="outlined">
        <CSVLink data={csvData} style={{ textDecoration: "none" }}>
          {" "}
          Download Sample File
        </CSVLink>
      </Button>
    );
  }
}
