import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Grid,
  Button,
  IconButton,
  Typography,
  DialogActions,
  DialogContent,
  Dialog as MUIDialog,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import { Cancel } from "@mui/icons-material";

import { useAxios, useAlert } from "../../hooks";
import DateFilterData from "./DateFilterData";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "800px" },
}));

export default function FilterDataDialog({
  memberList,
  dialogOpen,
  filterData,
  setUserData,
  setDialogOpen,
  setFilterData,
  setMemberList,
}) {
  const axios = useAxios();
  const classes = useStyles();
  const { alert } = useAlert();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleClose = () => {
    setDialogOpen(false);
  };

  function clearFilter() {
    setFilterData({
      start_date: "",
      end_date: "",
      req_members: "",
    });
  }

  return (
    <React.Fragment>
      <MUIDialog
        classes={{ paper: classes.paper }}
        open={dialogOpen}
        onClose={handleClose}
      >
        <Grid
          container
          spacing={0}
          style={{
            borderBottom: "1px solid #cbcbcb",
          }}
        >
          <Grid style={{ padding: "16px 24px" }} item xs={8}>
            <Typography variant="h5">Filter Data</Typography>
          </Grid>

          <Grid item xs={4}>
            <IconButton
              disableRipple
              style={{ float: "right", padding: "16px 24px" }}
              onClick={handleClose}
            >
              <Cancel />
            </IconButton>
          </Grid>
        </Grid>

        <React.Fragment>
          <DialogContent
            style={{
              paddingTop: "10px",
            }}
          >
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography
                  variant="caption1"
                  style={{ color: "rgb(128, 128, 128)" }}
                >
                  Apply filter to the usage data.
                </Typography>
              </Grid>

              <DateFilterData
                date={date}
                setDate={setDate}
                filterData={filterData}
                memberList={memberList}
                setUserData={setUserData}
                setMemberList={setMemberList}
                setFilterData={setFilterData}
                setDialogOpen={setDialogOpen}
              />
            </Grid>
          </DialogContent>

          <DialogActions style={{ padding: "12px 24px 24px" }}>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs></Grid>

              <Grid item style={{ textAlign: "end" }}>
                <Button
                  onClick={clearFilter}
                  variant="outlined"
                  color="primary"
                >
                  Clear Filter
                </Button>
              </Grid>

              <Grid item style={{ textAlign: "end" }}>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={applyFilter}
                  loading={loading}
                >
                  Apply Filter
                </LoadingButton>
              </Grid>
            </Grid>
          </DialogActions>
        </React.Fragment>
      </MUIDialog>
    </React.Fragment>
  );

  function applyFilter() {
    setLoading(true);
    if (filterData.end_date !== "" && filterData.start_date !== "") {
      var d = new Date(filterData.end_date);
      d.setDate(d.getDate() + 1);
      filterData.end_date = d.getTime();
    }

    if (filterData.end_date === "" && filterData.start_date === "") {
      var today = new Date();
      var priorDate = new Date(new Date().setDate(today.getDate() - 30));

      filterData.start_date = priorDate.getTime();
      filterData.end_date = today.getTime();
    }

    axios({
      url: "/usage",
      method: "POST",
      data: {
        start_date: filterData.start_date,
        end_date: filterData.end_date,
        req_members: filterData.reqMember,
      },
    }).then((response) => {
      setLoading(false);
      if (response.status) {
        dispatch(alert({ message: "Details Filtered!", type: "success" }));
        setFilterData({
          start_date: "",
          end_date: "",
          req_members: filterData.reqMember,
        });
        setDate([
          {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
          },
        ]);
        setDialogOpen(false);
        setUserData && setUserData(response.data);
      }
    });
  }
}
