import React from "react";

import { DataTable } from "../../../components";

export default function Table({ voterDetails }) {
  var formatted_data = [];
  if (!!voterDetails) {
    var keys = Object.keys(voterDetails);
    var values = Object.values(voterDetails);
    for (var i = 1; i < keys.length; i++) {
      if (keys[i] !== "file_data") {
        formatted_data.push({ key: keys[i], value: values[i] });
      }
    }
  }

  const options = {
    sortOrder: {
      name: "created_at",
      direction: "desc",
      rowsPerPage: 50,
      rowsPerPageOptions: [100],
      filter: false,
      viewColumns: false,
    },
  };

  const columns = [
    {
      name: "key",
      label: "Key",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "value",
      label: "Value",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
  ];

  return (
    <React.Fragment>
      <DataTable columns={columns} options={options} data={formatted_data} />
    </React.Fragment>
  );
}
