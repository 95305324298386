import ENV from "../config/config";

const URL_PREFIX = ENV === "main" ? "" : ENV + ".";

const env = ENV === "dev" ? "" : URL_PREFIX;

const URL =
  ENV === "dev"
    ? "https://dev.backend-kyc-dashboard.metawing.ai/kyc/"
    : "https://" + env + "backend-kyc-dashboard.metawing.ai/kyc/";

const BASE_URL = {
  passport: {
    url: URL,
    version: "v1/",
  },
};

export default BASE_URL;
