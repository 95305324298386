import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FormDisplay from "./FormDisplay";
import { useAxios, useAlert } from "../../../hooks";
import { signInMember } from "../../../redux/slices/memberSlice";
import { signInDetailsObject, validationSchema } from "./signInDetailsObject";
import pages from "../../../constants/pages";

export default function Form() {
  const axios = useAxios({ baseURL: "passport", disableAuthToken: true });

  const { alert } = useAlert();
  const { permissions } = useSelector((state) => state.memberDetails);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Formik
      enableReinitialize
      initialValues={signInDetailsObject}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        axios({
          url: "/admin/login",
          method: "POST",
          data: {
            email: formData.email,
            password: formData.password,
          },
          disableRedirect: true,
        })
          .then((res) => {
            if (res.status) {
              dispatch(
                signInMember({
                  authToken: res.data.auth_token,
                })
              );
              console.log(permissions);

              dispatch(alert({ message: "Welcome!", type: "success" }));
              navigate(pages.home.route);
            }
            setSubmitting(false);
          })
          .catch((err) => {
            console.error(err);
          });
      }}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>
          <FormDisplay formikProps={formikProps} />
        </form>
      )}
    </Formik>
  );
}
