import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Grid,
  Button,
  IconButton,
  Typography,
  DialogActions,
  DialogContent,
  Dialog as MUIDialog,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import { Cancel } from "@mui/icons-material";

import FilterDataForm from "./FilterDataForm";
import { useAxios, useComponent } from "../../hooks";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "800px" },
}));

export default function FilterDataDialog({
  apiList,
  dialogOpen,
  setDialogOpen,
  memberList,
  setMemberList,
  filterData,
  setFilterData,
  setGenerateLogId,
}) {
  const axios = useAxios();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { alert } = useComponent();

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  return (
    <React.Fragment>
      <MUIDialog
        classes={{ paper: classes.paper }}
        open={dialogOpen}
        onClose={handleClose}
      >
        <Grid
          container
          spacing={0}
          style={{
            borderBottom: "1px solid #cbcbcb",
          }}
        >
          <Grid style={{ padding: "16px 24px" }} item xs={8}>
            <Typography variant="h5">Filter Log</Typography>
          </Grid>

          <Grid item xs={4}>
            <IconButton
              disableRipple
              style={{ float: "right", padding: "16px 24px" }}
              onClick={handleClose}
            >
              <Cancel />
            </IconButton>
          </Grid>
        </Grid>

        <React.Fragment>
          <DialogContent
            style={{
              paddingTop: "10px",
            }}
          >
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography
                  variant="caption1"
                  style={{ color: "rgb(128, 128, 128)" }}
                >
                  Apply filter to the table data to Log list
                </Typography>
              </Grid>

              <FilterDataForm
                setState={setState}
                state={state}
                apiList={apiList}
                filterData={filterData}
                memberList={memberList}
                setMemberList={setMemberList}
                setFilterData={setFilterData}
                setGenerateLogId={setGenerateLogId}
              />
            </Grid>
          </DialogContent>

          <DialogActions style={{ padding: "12px 24px 24px" }}>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs></Grid>

              <Grid item style={{ textAlign: "end" }}>
                <Button
                  onClick={clearFilter}
                  variant="outlined"
                  disabled={!filterData.request_name}
                  color="primary"
                >
                  Clear apply filter
                </Button>
              </Grid>

              <Grid item style={{ textAlign: "end" }}>
                <LoadingButton
                  onClick={applyFilter}
                  variant="contained"
                  color="primary"
                  disabled={!filterData.user_id}
                  loading={loading}
                >
                  Apply filter
                </LoadingButton>
              </Grid>
            </Grid>
          </DialogActions>
        </React.Fragment>
      </MUIDialog>
    </React.Fragment>
  );

  function handleClose() {
    setDialogOpen(false);
  }

  function applyFilter() {
    setLoading(true);
    if (filterData.end_time !== "" && filterData.start_time !== "") {
      var d = new Date(filterData.end_time);
      d.setDate(d.getDate() + 1);
      filterData.end_time = d.getTime();
    }

    if (filterData.end_time === "" && filterData.start_time === "") {
      var today = new Date();
      var priorDate = new Date(new Date().setDate(today.getDate() - 30));

      filterData.start_time = priorDate.getTime();
      filterData.end_time = today.getTime();
    }

    axios({
      url: "/logs-list",
      method: "POST",
      data: {
        start_date: filterData.start_time,
        end_date: filterData.end_time,
        user_id: filterData.user_id,
        status: filterData.status,
        api_name: filterData.api_name,
      },
    })
      .then((response) => {
        setLoading(false);
        if (response.status) {
          if (response.data.length > 0) {
            alert.success(response.message.displayMessage);
            setFilterData({
              status: "",
              start_time: "",
              end_time: "",
              api_name: "",
              user_id: filterData.user_id,
            });
            setState([
              {
                startDate: new Date(),
                endDate: new Date(),
                key: "selection",
              },
            ]);
            setDialogOpen(false);
          } else {
            dispatch(alert.error("No Logs Found"));
            setDialogOpen(false);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function clearFilter() {
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);

    setFilterData({
      status: "",
      start_time: "",
      end_time: "",
      api_name: "",
      user_id: "",
    });
  }
}
