/* Routes for sidebar menu
  For a single entry: {
    name: Entry Display Name,
    icon: Entry Icon,
    ID: dashboardRoutes.[route name].ID,
    path: dashboardRoutes.[route name].path,
  }

  For a folder: {
    name: Folder Display Name,
    icon: Folder Icon,
    ID: random ID for key for component in the loop,
    children: [{
      name: Sub entry name,
      path: dashboardRoutes.[route name].path,
    }],
  }
*/

import { HiOutlineDocument } from "react-icons/hi";
import { VscOutput } from "react-icons/vsc";
import { MdInsights } from "react-icons/md";
import { GoCreditCard } from "react-icons/go";
import { GiVote, GiMatchbox } from "react-icons/gi";
import { FiCopy } from "react-icons/fi";
import { TbLicense } from "react-icons/tb";
import { FaPassport } from "react-icons/fa";
import { FaRegIdCard } from "react-icons/fa";
import { FaRegistered } from "react-icons/fa";
import { FaAddressCard } from "react-icons/fa";
import { AiOutlineScan } from "react-icons/ai";
import { SiFarfetch } from "react-icons/si";
import { PiStudent } from "react-icons/pi";
import { TbCloudLock } from "react-icons/tb";
import { GiStripedSun } from "react-icons/gi";
import { MdContactPhone } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";

import dashboardLayoutRoutes from "../../../router/router components/dashboardLayoutRoutes";

const routes = [
  {
    name: "Insights",
    icon: MdInsights,
    ID: dashboardLayoutRoutes.userRoute.ID,
    path: dashboardLayoutRoutes.userRoute.path,
  },
  {
    name: "Verify Aadhar",
    icon: FaAddressCard,
    ID: dashboardLayoutRoutes.aadharRoute.ID,
    path: dashboardLayoutRoutes.aadharRoute.path,
  },
  {
    name: "Verify Driving License",
    icon: TbLicense,
    ID: dashboardLayoutRoutes.drivingLicenseRoute.ID,
    path: dashboardLayoutRoutes.drivingLicenseRoute.path,
  },
  {
    name: "Verify OCR",
    icon: AiOutlineScan,
    ID: dashboardLayoutRoutes.ocrRoute.ID,
    path: dashboardLayoutRoutes.ocrRoute.path,
  },
  {
    name: "Student Verification",
    icon: PiStudent,
    ID: dashboardLayoutRoutes.emailRoute.ID,
    path: dashboardLayoutRoutes.emailRoute.path,
  },
  {
    name: "Digi Locker",
    icon: TbCloudLock,
    ID: dashboardLayoutRoutes.digiLockerRoute.ID,
    path: dashboardLayoutRoutes.digiLockerRoute.path,
  },
  {
    name: "Passenger Verification",
    icon: GiStripedSun,
    ID: dashboardLayoutRoutes.digiYatraRoute.ID,
    path: dashboardLayoutRoutes.digiYatraRoute.path,
  },
  {
    name: "Phone Verification",
    icon: FaPhoneAlt,
    ID: dashboardLayoutRoutes.phoneVerfiyRoute.ID,
    path: dashboardLayoutRoutes.phoneVerfiyRoute.path,
  },

  {
    name: "Phone Verification With Consent",
    icon: FaPhoneAlt,
    ID: dashboardLayoutRoutes.phoneVerfiy01Route.ID,
    path: dashboardLayoutRoutes.phoneVerfiy01Route.path,
  },
  {
    name: "Verify PAN",
    icon: FaRegIdCard,
    ID: dashboardLayoutRoutes.panRoute.ID,
    path: dashboardLayoutRoutes.panRoute.path,
  },
  {
    name: "Verify Passport",
    icon: FaPassport,
    ID: dashboardLayoutRoutes.passportRoute.ID,
    path: dashboardLayoutRoutes.passportRoute.path,
  },
  {
    name: "Fetch Passport",
    icon: SiFarfetch,
    ID: dashboardLayoutRoutes.passportFetchRoute.ID,
    path: dashboardLayoutRoutes.passportFetchRoute.path,
  },
  {
    name: "Verify RC",
    icon: FaRegistered,
    ID: dashboardLayoutRoutes.registerCertificateRoute.ID,
    path: dashboardLayoutRoutes.registerCertificateRoute.path,
  },

  {
    name: "Verify Voter ID",
    icon: GiVote,
    ID: dashboardLayoutRoutes.voterRoute.ID,
    path: dashboardLayoutRoutes.voterRoute.path,
  },
  {
    name: "Bulk RC Process",
    icon: FiCopy,
    ID: dashboardLayoutRoutes.bulkProcessRoute.ID,
    path: dashboardLayoutRoutes.bulkProcessRoute.path,
  },
  {
    name: "Logs",
    icon: VscOutput,
    ID: dashboardLayoutRoutes.logsRoute.ID,
    path: dashboardLayoutRoutes.logsRoute.path,
  },
  {
    name: "Logs Request",
    icon: HiOutlineDocument,
    ID: dashboardLayoutRoutes.logsRequestRoute.ID,
    path: dashboardLayoutRoutes.logsRequestRoute.path,
  },
  {
    name: "Fuzzy Match",
    icon: GiMatchbox,
    ID: dashboardLayoutRoutes.matchRoute.ID,
    path: dashboardLayoutRoutes.matchRoute.path,
  },
  {
    name: "OCR",
    icon: GoCreditCard,
    ID: dashboardLayoutRoutes.fileUploadRoute.ID,
    path: dashboardLayoutRoutes.fileUploadRoute.path,
  },
];

export default routes;
