import { Fragment, useState } from "react";
import { Formik } from "formik";

import ImgCapture from "./ImgCapture";
import Loader from "../../../components/Loader";
import { useAxios, useComponent } from "../../../hooks";
import { useDispatch } from "react-redux";

function FaceImageFrom({ processData, setProcessData, setProcessStatus }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { alert } = useComponent();
  const axios = useAxios({
    customBaseURL: "https://uat.component.dolphinchat.ai",
    disableAuthToken: false,
  });
  const [formData, setFormData] = useState({ aadharQRCodeImg: "" });
  return (
    <Fragment>
      <Formik
        initialValues={formData}
        enableReinitialize
        onSubmit={(formData) => {
          setLoading(true);

          axios({
            url: "aadhar-verification/face-match",
            method: "POST",
            data: {
              reference_id: processData.request_id,
              image_url: formData.aadharQRCodeImg,
            },
            disableRedirect: true,
            disableError: true,
          })
            .then((response) => {
              if (response.status) {
                setLoading(false);
                setProcessData((prev) => ({
                  ...prev,
                  finalStatus: response?.data,
                }));
                setProcessStatus((prev) => ({
                  ...prev,
                  faceMatch: true,
                }));
              }
            })
            .catch((error) => {
              dispatch(alert.error("error"));
              setProcessStatus((prev) => ({
                ...prev,
                faceMatch: true,
              }));
              setLoading(false);
            });
        }}
      >
        {(bioFormikProps) => {
          return (
            <Loader>
              <ImgCapture
                bioFormikProps={bioFormikProps}
                onSubmit={bioFormikProps.handleSubmit}
                loading={loading}
              />
            </Loader>
          );
        }}
      </Formik>
    </Fragment>
  );
}

export default FaceImageFrom;
