import React, { useState } from "react";

import Form from "./Form";
import { panObject } from "./panObject";
import { PageHeader } from "../../components";

export default function PanDetails() {
  const [panDetails, setPanDetails] = useState([]);
  const [formData, setFormData] = useState(panObject);

  return (
    <React.Fragment>
      <PageHeader pageName="Pan Details" />

      <Form
        formData={formData}
        setFormData={setFormData}
        panDetails={panDetails}
        setPanDetails={setPanDetails}
      />
    </React.Fragment>
  );
}
