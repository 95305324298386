import React, { useState } from "react";

import Form from "./Form";
import { ocrObject } from "./ocrObject";
import { PageHeader } from "../../components";
import Students from "./student/student";

export default function StudentOcrDetails() {
  const [ocrDetails, setOcrDetails] = useState();
  const [formData, setFormData] = useState(ocrObject);

  return (
    <React.Fragment>
      <PageHeader pageName="Student Verification Details" />

      <Students />

      <Form
        formData={formData}
        ocrDetails={ocrDetails}
        setFormData={setFormData}
        setOcrDetails={setOcrDetails}
      />
    </React.Fragment>
  );
}
