import React, { useState } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";

import FormDisplay from "./FormDisplay";
import { useAlert, useAxios } from "../../hooks";
import { validationSchema } from "./ocrObject";
import { ComponentHeader, TextField } from "../../components";
import { Button, Grid } from "@mui/material";
import Paper from "./../../components/Paper";
import PhoneDataTableList from "./phone data table components/PhoneDataTableList";
import { Fragment } from "react";
import { LoadingButton } from "@mui/lab";

export default function Form({}) {
  const axios = useAxios({});
  const { alert } = useAlert();
  const dispatch = useDispatch();

  const [phoneData, setPhoneData] = useState({});

  const [formData, setFormData] = useState({
    mobileNo: "",
  });

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      onSubmit={(formData, { setSubmitting }) => {
        setSubmitting(true);
        axios({
          url: "auth/non-consented/" + formData.mobileNo,
          method: "GET",
          disableRedirect: true,
        }).then(async (res) => {
          if (res.status) {
            setPhoneData(res.data);
            dispatch(alert({ message: "Details fetched!", type: "success" }));
          }
          setSubmitting(false);
        });
      }}
    >
      {(formikProps) => (
        <Fragment>
          <Paper>
            <Grid item xs={12}>
              <ComponentHeader title={"Phone Verification Without Consent"} />
            </Grid>

            <Grid item xs={4}>
              <TextField
                required
                name="mobileNo"
                label="Mobile No"
                formikProps={formikProps}
              />
            </Grid>

            <Grid item xs></Grid>
            <Grid item alignSelf="center">
              <LoadingButton
                type="submit"
                variant="contained"
                fullWidth
                loading={formikProps.isSubmitting}
                onClick={formikProps.handleSubmit}
              >
                Verify
              </LoadingButton>
            </Grid>
          </Paper>

          <PhoneDataTableList Details={phoneData} />
        </Fragment>
      )}
    </Formik>
  );
}
