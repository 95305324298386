import {
  CircularProgress,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import styled from "styled-components";
import { LoadingButton } from "@mui/lab";
import { Fragment, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

import { useAxios } from "../../hooks";
import { Paper, TextField } from "../../components";

const VerifiedIcon = styled(DoneIcon)`
  color: green;
  vertical-align: middle;
  font-size: 30px;
`;
const UnverifiedIcon = styled(CloseIcon)`
  color: red;
  vertical-align: middle;
  font-size: 30px;
`;

function EmailIDForm({ steps, setSteps }) {
  const axios = useAxios();

  const [loading, setLoading] = useState(false);
  return (
    <Formik
      initialValues={{ email: "" }}
      onSubmit={(formData) => {
        setLoading(true);
        axios({
          url: "/students/verify-account/" + formData.email,
          disableRedirect: true,
          disableError: true,
        }).then((response) => {
          if (response?.status) {
            setSteps((prev) => ({
              ...prev,
              email: formData.email,
              responseMessage: response?.message.displayMessage,
              step: 0,
              status: response?.status,
              emailStatus: response?.status,
            }));
          } else {
            setSteps((prev) => ({
              ...prev,
              responseMessage: response?.displayMessage,
              step: 0,
              status: response?.status,
              emailStatus: response?.status,
            }));
          }
          setLoading(false);
        });
      }}
    >
      {(formikProps) => {
        return (
          <Paper>
            <Grid
              item
              container
              justifyContent={"center"}
              spacing={2}
              sx={{
                padding: "15px",
                width: "80%",
                marginLeft: "11%",
                borderRadius: "15px",
              }}
            >
              <Grid item container justifyContent={"center"}>
                <Grid item>
                  <Typography variant="h4" fontWeight={700}>
                    Student Verification
                  </Typography>
                </Grid>
              </Grid>

              <Grid item container justifyContent={"start"} mt={"40px"}>
                <Grid item>
                  <Typography
                    variant="h6"
                    fontWeight={700}
                    color={"#868686"}
                    m={"7.5px 0px"}
                  >
                    Instructions :
                  </Typography>
                  <Typography
                    variant="body"
                    fontWeight={600}
                    color={"#b5b5b5"}
                    m={"7.5px 0px"}
                  >
                    1. Share your college or school email address in the
                    provided field.
                  </Typography>
                  <br />
                  <Typography
                    variant="body"
                    fontWeight={600}
                    color={"#b5b5b5"}
                    m={"7.5px 0px"}
                  >
                    2. Enter your email address in the box then press Enter or
                    click outside the box to verify it.
                  </Typography>
                </Grid>
              </Grid>

              <Grid item container justifyContent={"center"} xs={12}>
                <TextField
                  name="email"
                  label={"Enter Your Email Id"}
                  formikProps={formikProps}
                  onBlur={formikProps.handleSubmit}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      formikProps.handleSubmit();
                    }
                  }}
                  sx={{ width: "70%" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {loading && <CircularProgress />}
                        {steps.status && !loading && (
                          <DoneIcon sx={{ color: "green" }} />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {Boolean(steps?.responseMessage && steps.step === 0) && (
                <Grid item container justifyContent={"center"}>
                  <Typography variant="h4">
                    {steps.status ? <VerifiedIcon /> : <UnverifiedIcon />}
                    {steps.responseMessage}
                  </Typography>
                </Grid>
              )}

              <Grid item xs={5}>
                <LoadingButton
                  variant="contained"
                  size="large"
                  fullWidth
                  disabled={!Boolean(steps.status)}
                  onClick={() =>
                    setSteps((prev) => ({
                      ...prev,
                      responseMessage: "",
                      idCard: "",
                      step: 1,
                      status: false,
                    }))
                  }
                  sx={{
                    height: "100%",
                    borderRadius: "10px",
                    "&:hover": { borderRadius: "10px" },
                  }}
                >
                  Next : Upload Student Id
                </LoadingButton>
              </Grid>
            </Grid>
          </Paper>
        );
      }}
    </Formik>
  );
}

export default EmailIDForm;
