import React, { useState } from "react";

import Form from "./Form";
import { voterObject } from "./voterObject";
import { PageHeader } from "../../components";

export default function VoterDetails() {
  const [formData, setFormData] = useState(voterObject);
  const [voterDetails, setVoterDetails] = useState([]);

  return (
    <React.Fragment>
      <PageHeader pageName="Voter Details" />

      <Form
        formData={formData}
        setFormData={setFormData}
        voterDetails={voterDetails}
        setVoterDetails={setVoterDetails}
      />
    </React.Fragment>
  );
}
