import React, { useState } from "react";
import {
  DialogContent,
  Dialog,
  Grid,
  Button,
  Typography,
  Checkbox,
} from "@mui/material";
import styled from "styled-components/macro";
import { LoadingButton } from "@mui/lab";

import { useAxios } from "../../hooks";

const Card = styled(Dialog)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export default function DownloadDialog({
  formikProps,
  downloadDialog,
  setDownloadDialog,
}) {
  const axios = useAxios();

  const [loading, setLoading] = useState(false);

  const [checked, setChecked] = useState(false);

  const handleDownloadDialogClose = () => {
    setDownloadDialog({ popUp: false, clientId: "" });
  };

  const handleDownload = () => {
    setLoading(true);
    axios({
      url: "digilocker/download/doc",
      method: "POST",
      data: {
        mobile: formikProps.values?.mobile,
        client_id: downloadDialog.clientId,
      },
      disableRedirect: true,
    })
      .then((response) => {
        if (response.status) {
          window.open(response.data);

          formikProps.setTouched(true);

          formikProps.setValues((prev) => ({
            ...prev,
            full_name: "",
            mobile: "",
            email: "",
          }));

          setDownloadDialog({ popUp: false, clientId: "" });

          setChecked(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  function handleChange() {
    setChecked(!checked);
  }

  return (
    <Card
      open={downloadDialog?.popUp}
      onClose={handleDownloadDialogClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "850px!important",
        },
      }}
    >
      <DialogContent>
        {downloadDialog?.url && (
          <Grid container spacing={3} style={{ padding: "20px" }}>
            <Grid
              item
              xs={12}
              display="flex"
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography variant="h6">
                After giving your consent on the new tab, just come back here to
                get the document
              </Typography>
              <LoadingButton
                variant="contained"
                onClick={() => {
                  setDownloadDialog((prev) => ({ ...prev, url: "" }));
                  window.open(downloadDialog?.url);
                }}
              >
                Consent Link
              </LoadingButton>
            </Grid>
          </Grid>
        )}

        {!downloadDialog?.url && (
          <Grid container spacing={3} style={{ padding: "20px" }}>
            <Grid item xs={9} display="flex" alignItems={"center"}>
              <Checkbox checked={checked} onChange={handleChange} />

              <Typography variant="h6">
                I confirm that I have given my consent
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <LoadingButton
                loading={loading}
                variant="contained"
                disabled={!checked}
                onClick={handleDownload}
              >
                Download Document
              </LoadingButton>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Card>
  );
}
