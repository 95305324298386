import React from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";

import FormDisplay from "./FormDisplay";
import { validationSchema } from "./matchObject";
import { useAlert, useAxios } from "../../hooks";

export default function Form({ formData, matchName, setMatchName }) {
  const axios = useAxios();
  const { alert } = useAlert();
  const dispatch = useDispatch();

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        axios({
          url: "/admin/namecheck/match-name",
          method: "POST",
          data: { name1: formData.firstName, name2: formData.secondName },
        })
          .then(async (res) => {
            setSubmitting(false);
            if (res.status) {
              setMatchName(res);
              dispatch(alert({ message: "Details fetched!", type: "success" }));
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }}
    >
      {(formikProps) => (
        <FormDisplay formikProps={formikProps} matchName={matchName} />
      )}
    </Formik>
  );
}
