import React, { useState } from "react";
import { FilterList } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

import FilterDataDialog from "./FilterDataDialog";

export default function CustomToolbar({
  apiList,
  setLogsRequest,
  filterData,
  setFilterData,
}) {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <React.Fragment>
      <Tooltip title={"Filter Data"}>
        <IconButton onClick={handleClick}>
          <FilterList />
        </IconButton>
      </Tooltip>

      <FilterDataDialog
        dialogOpen={dialogOpen}
        filterData={filterData}
        apiList={apiList}
        setLogsRequest={setLogsRequest}
        setDialogOpen={setDialogOpen}
        setFilterData={setFilterData}
      />
    </React.Fragment>
  );

  function handleClick() {
    setDialogOpen(true);
  }
}
