import React, { Fragment } from "react";
import Grid from "@mui/material/Grid";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";

export default function VerificationComponent({}) {
  let data = { aadhar_check: true, flight_check: true, face_check: true };
  const denied = Object.values(data).some((value) => !value);

  return (
    <Fragment>
      <Fragment>
        <Grid container sx={{ p: "0px 40px 40px 40px" }} spacing={5}>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            {denied ? (
              <DoDisturbIcon
                sx={{
                  color: "#fb0606",
                  marginRight: "10px",
                  fontSize: "75px",
                }}
              />
            ) : (
              <CheckCircleOutlineIcon
                sx={{
                  color: "#4CAF50",
                  marginRight: "10px",
                  fontSize: "75px",
                }}
              />
            )}
          </Grid>
        </Grid>
      </Fragment>
    </Fragment>
  );
}
