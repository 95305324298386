import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useMatch } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  List as MuiList,
  ListItemButton as MuiListItemButton,
  ListItemIcon as MuiListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import {
  Code,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
  KeyboardArrowRight,
} from "@mui/icons-material";
import styled from "styled-components/macro";

import sidebarRoutes from "./sidebarRoutes";

const Wrapper = styled(Box)`
  flex: 1;
  max-height: calc(100% - 55px);
  color: #fff;
`;

const List = styled(MuiList)`
  padding: 0;
`;

const ListItemButton = styled(MuiListItemButton)`
  height: 40px;
  margin-top: 5px;
  border-radius: 4px;
`;

const ListItemIcon = styled(MuiListItemIcon)`
  min-width: 30px;
  color: #fff;
`;

export default function Menu({ sidebarState }) {
  const navigate = useNavigate();

  const { permissions } = useSelector((state) => state.memberDetails);
  const permittedRoutes = [];

  for (let route of sidebarRoutes) {
    Object.keys(permissions).forEach((permission) => {
      if (permission === route.name && permissions[permission]) {
        permittedRoutes.push(route);
      }
    });
  }

  return (
    <Wrapper>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <List>
          {permittedRoutes.map(({ name, icon, ID, path, children }) =>
            children ? (
              <ListFolder
                key={ID}
                name={name}
                icon={icon}
                children={children}
              />
            ) : (
              <ListItem key={ID} name={name} icon={icon} path={path} />
            )
          )}
        </List>
      </PerfectScrollbar>
    </Wrapper>
  );

  function ListFolder({ name, icon: Icon, children }) {
    const [folderOpen, setFolderOpen] = useState(false);

    return (
      <Fragment>
        <ListItemButton onClick={() => setFolderOpen(!folderOpen)}>
          <ListItemIcon>
            {<Icon style={{ width: "16px", height: "16px" }} />}
          </ListItemIcon>

          {sidebarState.open && <ListItemText primary={name} />}

          {folderOpen ? (
            <KeyboardArrowUpOutlined />
          ) : (
            <KeyboardArrowDownOutlined />
          )}
        </ListItemButton>

        <Collapse
          in={folderOpen}
          timeout="auto"
          unmountOnExit
          style={{ marginInline: "10px" }}
        >
          <List>
            {children.map(({ name, ID, path }) => (
              <ListItem
                key={ID}
                name={name}
                path={path}
                icon={KeyboardArrowRight}
              />
            ))}
          </List>
        </Collapse>
      </Fragment>
    );
  }

  function ListItem({ name, icon: Icon, path }) {
    const match = useMatch(path);

    return (
      <ListItemButton onClick={() => navigate(path)} selected={!!match}>
        <ListItemIcon>
          {
            <Icon
              color="white"
              style={{ marginBlock: "6px", width: "16px", height: "16px" }}
            />
          }
        </ListItemIcon>

        {sidebarState.open && <ListItemText primary={name} />}
      </ListItemButton>
    );
  }
}
