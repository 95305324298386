import React from "react";

import { DataTable } from "../../../components";

export default function Table({ drivingLiciense }) {
  var license_data = [];

  if (!!drivingLiciense) {
    var keys = Object.keys(drivingLiciense);
    var values = Object.values(drivingLiciense);
    for (var i = 0; i < keys.length; i++) {
      if (keys[i] === "address") {
        for (let add of values[i]) {
          let address =
            add.completeAddress +
            " " +
            add.district +
            " " +
            add.state +
            " " +
            add.pin +
            " " +
            add.country;
          license_data.push({ key: [add.type] + " Address", value: address });
        }
      }
      if (keys[i] === "cov_details") {
        for (let add of values[i]) {
          license_data.push({ key: "Covered Vehciles", value: add.cov });
        }
      } else if (
        keys[i] !== "validity" &&
        keys[i] !== "statusDetails" &&
        keys[i] !== "address"
      ) {
        license_data.push({ key: keys[i], value: values[i] });
      }
    }
  }

  const options = {
    sortOrder: {
      name: "created_at",
      direction: "desc",
      rowsPerPage: 50,
      rowsPerPageOptions: [100],
      filter: false,
      viewColumns: false,
    },
  };

  const columns = [
    {
      name: "key",
      label: "Key",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "value",
      label: "Value",
      options: {
        customBodyRender: (value) => {
          return "value:" + value[0] ? value : "Not Available";
        },
      },
    },
  ];

  return <DataTable columns={columns} data={license_data} options={options} />;
}
