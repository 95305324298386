import React from "react";
import { CSVLink } from "react-csv";
import { LoadingButton } from "@mui/lab";
import { Event } from "@mui/icons-material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Grid, Button, InputAdornment } from "@mui/material";

import { ComponentHeader, Paper, TextField } from "../../components";
import DrivingLinceseTableList from "./driving license table/DrivingLicenseTableList";

export default function FormDisplay({
  formikProps,
  drivingLiciense,
  setDrivingLicense,
}) {
  function handleDate(value) {
    formikProps.setValues((val) => ({
      ...val,
      dateOfBirth: value,
    }));
  }

  return (
    <React.Fragment>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader
            title={"Driving License Details"}
            buttons={[<SampleFile />]}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            required
            name="drivingLicenseNumber"
            label="Driving License Number"
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={6}>
          <MobileDatePicker
            name="dateOfBirth"
            label="Date of birth"
            inputFormat="dd/MM/yyyy"
            value={formikProps.values.dateOfBirth}
            onChange={(value) => handleDate(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                name={"dateOfBirth"}
                value={formikProps.values.dateOfBirth}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Event />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs></Grid>
        <Grid item alignSelf="center">
          <LoadingButton
            type="submit"
            variant="contained"
            fullWidth
            onClick={formikProps.handleSubmit}
            disabled={!formikProps.dirty || !formikProps.isValid}
            loading={formikProps.isSubmitting}
          >
            Fetch Driving License Details
          </LoadingButton>
        </Grid>
      </Paper>

      <DrivingLinceseTableList
        drivingLiciense={drivingLiciense}
        setDrivingLicense={setDrivingLicense}
      />
    </React.Fragment>
  );

  function SampleFile() {
    const csvData = [
      {
        fileName: "file",
        docType: "Driving License",
        personal: true,
        name: "SHUBHAM SRIVASTAVA",
        number: "DL0320160535052",
        vehicleNumber: null,
        actionDate: "2036-04-01",
        date: "2016-04-02",
        biller: null,
        amount: null,
        accountNumber: null,
        ifscCode: null,
      },
    ];

    return (
      <Button variant="outlined">
        <CSVLink data={csvData} style={{ textDecoration: "none" }}>
          {" "}
          Download Sample File
        </CSVLink>
      </Button>
    );
  }
}
