import * as Yup from "yup";

export const drivingLicenseObject = {
  dateOfBirth: "",
  drivingLicenseNumber: "",
};

export const validationSchema = Yup.object().shape({
  dateOfBirth: Yup.string().required("This field is required"),
  drivingLicenseNumber: Yup.string().required("This field is required."),
});
