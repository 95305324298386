import * as Yup from "yup";

export const registrationCertificateObject = {
  registrationCertificateNumber: "",
};

export function objectFromResponseData(responseData) {
  return {
    registrationCertificateNumber: responseData.rc_regn_no,
  };
}

export const validationSchema = Yup.object().shape({
  registrationCertificateNumber: Yup.string().required(
    "This field is required."
  ),
});
