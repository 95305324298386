import React, { useState, useEffect } from "react";

import Table from "./Table";
import pages from "../../../constants/pages";
import { PageHeader, Loader } from "../../../components";

import { useAxios, useComponent } from "../../../hooks";

export default function BulkProcessList() {
  const axios = useAxios();
  const { loader } = useComponent();

  const [rows, setRows] = useState(0);
  const [processes, setProcesses] = useState([]);
  const [processActivity, setProcessActivity] = useState({
    cancel: "",
    regenerateFile: "",
  });

  useEffect(() => {
    loader.start();

    axios({
      url: "vahan/processes?skip=0&limit=25",
    }).then((response) => {
      if (response.status) {
        setProcesses(response.data.paginatedResults);
        setRows(response.data.totalCount[0]?.count);
        loader.apiComplete();
      }
    });
  }, [axios, loader, processActivity.cancel, processActivity.regenerateFile]);

  return (
    <Loader>
      <PageHeader
        pageName="Bulk Process List"
        addIcon={[
          "Create Bulk Process",
          pages.bulkProcessCreatePage.route,
          true,
        ]}
      />

      <Table
        processes={processes}
        setProcesses={setProcesses}
        setProcessActivity={setProcessActivity}
        rows={rows}
        setRows={setRows}
      />
    </Loader>
  );
}
