import React, { useState } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";

import FormDisplay from "./FormDisplay";
import { useAlert, useAxios } from "../../hooks";
import { validationSchema } from "./ocrObject";
import { ComponentHeader, TextField } from "../../components";
import { Button, Grid } from "@mui/material";
import Paper from "../../components/Paper";
import PhoneDataTableList from "./phone data table components/PhoneDataTableList";
import { Fragment } from "react";
import { LoadingButton } from "@mui/lab";

export default function Form({}) {
  const axios = useAxios({});
  const { alert } = useAlert();
  const dispatch = useDispatch();

  const [phoneData, setPhoneData] = useState({});

  const [mobileVerifyloading, setMobileVerifyLoading] = useState(false);

  const [formData, setFormData] = useState({
    mobileNo: "",
    mobileOTP: "",
  });

  const [responseData, setResponseData] = useState({
    otp: "",
    mobile: "",
    reference_id: "",
  });

  function handleMobile(formikProps) {
    setMobileVerifyLoading(true);
    axios({
      url: "auth/consented/" + formikProps.values.mobileNo,
      method: "GET",
      disableRedirect: true,
    }).then(async (res) => {
      if (res.status) {
        setResponseData((prev) => ({
          ...prev,
          reference_id: res.data.result.referenceId,
          mobile: formikProps.values.mobileNo,
        }));

        dispatch(alert({ message: "Details fetched!", type: "success" }));
      }
      setMobileVerifyLoading(false);
    });
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      onSubmit={(formData, { setSubmitting }) => {
        setSubmitting(true);
        axios({
          url: "auth/verify/otp",
          method: "POST",
          data: {
            otp: formData.mobileOTP,
            mobile: formData.mobileNo,
            reference_id: responseData.reference_id,
          },
          disableRedirect: true,
        }).then(async (res) => {
          if (res.status) {
            setResponseData((prev) => ({
              ...prev,
              reference_id: responseData.data,
            }));
            setPhoneData(res.data.result);
            dispatch(alert({ message: "Details fetched!", type: "success" }));
          }
          setSubmitting(false);
        });
      }}
    >
      {(formikProps) => (
        <Fragment>
          <Paper>
            <Grid item xs={12}>
              <ComponentHeader title={"Phone Verification Consent"} />
            </Grid>

            <Grid item xs={4}>
              <TextField
                required
                name="mobileNo"
                label="Mobile No"
                formikProps={formikProps}
              />
            </Grid>
            <Grid item alignSelf="center">
              <LoadingButton
                type="submit"
                variant="contained"
                fullWidth
                loading={mobileVerifyloading}
                onClick={() => handleMobile(formikProps)}
              >
                Verify mobile
              </LoadingButton>
            </Grid>
            <Grid item xs></Grid>

            <Grid item xs={4}>
              <TextField
                required
                name="mobileOTP"
                label="Mobile otp"
                formikProps={formikProps}
              />
            </Grid>
            <Grid item alignSelf="center">
              <LoadingButton
                type="submit"
                variant="contained"
                fullWidth
                loading={formikProps.isSubmitting}
                onClick={formikProps.handleSubmit}
              >
                Verify OTP
              </LoadingButton>
            </Grid>
          </Paper>

          <PhoneDataTableList Details={phoneData} />
        </Fragment>
      )}
    </Formik>
  );
}
