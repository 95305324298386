import { Fragment, useState } from "react";
import PageHeader from "./../../components/PageHeader";
import Paper from "./../../components/Paper";
import { Button, Grid } from "@mui/material";

import Form from "./QR code components/Form";
import FaceImageForm from "./Face Image components/FaceImageForm";
import Table from "./Table";
import { ComponentHeader } from "../../components";
import StageStepper from "./Stepper";

export default function DigiYatra() {
  const [processStatus, setProcessStatus] = useState({
    initial: true,
    qrImage: true,
    showPassengerDetails: false,
    faceMatch: false,
    loading: true,
  });

  const [processData, setProcessData] = useState({
    request_id: "",
    aadharCardDetails: [],
    finalStatus: {},
  });

  function handleStartProcess() {
    setProcessStatus((prev) => ({ ...prev, initial: true }));
  }

  return (
    <Fragment>
      <PageHeader pageName={"Passenger Verification"} />
      <ComponentHeader title={"Passenger Verification"} />
      <Paper
        sx={{
          // height: "calc(76vh - 47%)",
          // overflowY: "auto",
          display: "flex",
          justifyContent: "center !important",
          // alignItems: "center !important",
          "@media (max-width: 750px)": {
            // height: "calc(95vh - 63%)",
          },
        }}
      >
        {processStatus.qrImage &&
          !processStatus.showPassengerDetails &&
          !processStatus.faceMatch && (
            <Form
              setProcessData={setProcessData}
              setProcessStatus={setProcessStatus}
            />
          )}

        {!processStatus.qrImage &&
          !processStatus.showPassengerDetails &&
          !processStatus.faceMatch && (
            <Table
              rcDetails={processData.aadharCardDetails}
              setProcessData={setProcessData}
              setProcessStatus={setProcessStatus}
            />
          )}

        {!processStatus.qrImage &&
          processStatus.showPassengerDetails &&
          !processStatus.faceMatch && (
            <FaceImageForm
              processData={processData}
              setProcessData={setProcessData}
              setProcessStatus={setProcessStatus}
            />
          )}

        {!processStatus.qrImage &&
          processStatus.showPassengerDetails &&
          processStatus.faceMatch && (
            <StageStepper data={processData.finalStatus} />
          )}
      </Paper>
    </Fragment>
  );
}
