import { Grid, Button } from "@mui/material";

import {
  ComponentHeader,
  Paper,
  TextField,
  FileUpload,
} from "../../../components";

export default function FormDisplay({ formikProps }) {
  function sampleFile() {
    window.location.href =
      "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_undefinedSample_RCs_upload_file%20%282%29.csv";
  }

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title={"Bulk Process"}
          subtitle=""
          buttons={[<SampleFile />]}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          required
          name="fileName"
          label="Process Name"
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <FileUpload
          required
          name="fileURL"
          label="File URL"
          formikProps={formikProps}
          allowedExtension={".xsls,.xls,.csv"}
        />
      </Grid>
    </Paper>
  );

  function SampleFile() {
    return (
      <Button variant="outlined" onClick={sampleFile}>
        Download Sample File
      </Button>
    );
  }
}
