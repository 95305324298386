import React from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Grid, MenuItem, Typography } from "@mui/material";

import { TextField } from "../../../components";

export default function FilterDataForm({
  state,
  apiList,
  setState,
  filterData,
  setFilterData,
}) {
  function handleChange(item) {
    setState([item.selection]);

    const timestamp1 = new Date(item.selection.startDate).getTime();
    const timestamp2 = new Date(item.selection.endDate).getTime();

    setFilterData((prev) => ({
      ...prev,
      start_time: timestamp1,
      end_time: timestamp2,
    }));
  }

  function handleApiName(e) {
    setFilterData((prev) => ({
      ...prev,
      api_name: e.target.value,
    }));
  }

  function handleStatus(e) {
    setFilterData((prev) => ({
      ...prev,
      status: e.target.value,
    }));
  }

  function handleRequest(e) {
    setFilterData((prev) => ({
      ...prev,
      request_name: e.target.value,
    }));
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Filter by date</Typography>
              </Grid>

              <Grid item xs={12}>
                <DateRange
                  onChange={(item) => handleChange(item)}
                  ranges={state}
                  maxDate={new Date()}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Filter by API Name and Status.
                </Typography>
              </Grid>

              <Grid item xs={12} pt="5px">
                <TextField
                  required
                  name={"request_name"}
                  label="Request Name"
                  value={filterData.request_name}
                  onChange={handleRequest}
                />
              </Grid>

              <Grid item xs={12} pt="5px">
                <TextField
                  name={"api_name"}
                  label="API Name"
                  select
                  value={filterData.api_name}
                  onChange={handleApiName}
                >
                  {apiList.map((item) => (
                    <MenuItem key={item.key} value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name={"status"}
                  label="API Status"
                  value={filterData.status}
                  onChange={handleStatus}
                  select
                >
                  <MenuItem value="success">Success</MenuItem>
                  <MenuItem value="fail">Fail</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
