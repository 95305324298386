import React from "react";
import { Formik } from "formik";

import FormDisplay from "./FormDisplay";
import { validationSchema } from "./aadharDetailsObject";

export default function Form({
  hide,
  show,
  setShow,
  captcha,
  setHide,
  formData,
  setCaptcha,
  aadharDetails,
  setAadharDetails,
}) {
  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>
          <FormDisplay
            show={show}
            hide={hide}
            captcha={captcha}
            setShow={setShow}
            setHide={setHide}
            setCaptcha={setCaptcha}
            formikProps={formikProps}
            aadharDetails={aadharDetails}
            setAadharDetails={setAadharDetails}
          />
        </form>
      )}
    </Formik>
  );
}
