import React, { useState } from "react";

import Form from "./Form";

import { PageHeader } from "../../components";

export default function PhoneVerification() {
  return (
    <React.Fragment>
      <PageHeader pageName="Phone Verification Details" />

      <Form />
    </React.Fragment>
  );
}
