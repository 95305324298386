import { React, useState } from "react";
import TabList from "@mui/lab/TabList";
import ReactJson from "react-json-view";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Grid, Tab, Box, Button } from "@mui/material";

import Table from "./Table";
import { ComponentHeader, Paper } from "../../../components";

export default function PassportTableList({ PassportDetails }) {
  const [value, setValue] = useState("PassportDetail");

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  const object = {
    PassportDetails,
  };

  function exportData() {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(object)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "object.json";

    link.click();
  }
  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title={"Fetch Passport Details"}
          subtitle=""
          buttons={[<DownloadJsonFile />]}
        />
      </Grid>

      <Grid item xs={12}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Table View" value="PassportDetail" />
              <Tab label="Json View" value="JsonView" />
            </TabList>
          </Box>

          <TabPanel value="PassportDetail">
            <Table PassportDetails={PassportDetails} />{" "}
          </TabPanel>

          <TabPanel value="JsonView">
            <ReactJson src={object} />
          </TabPanel>
        </TabContext>
      </Grid>
    </Paper>
  );

  function DownloadJsonFile() {
    return (
      <Button color="primary" variant="contained" onClick={exportData}>
        Download Json Data
      </Button>
    );
  }
}
