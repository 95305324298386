import axios from "axios";
import BASE_URL from "../../../constants/baseURL";

export default async function signOut(_, { getState }) {
  const { memberDetails } = getState();
  await axios({
    baseURL: BASE_URL.passport.url + BASE_URL.passport.version,
    url: "admin/logout",
    method: "POST",
    headers: {
      Authorization: "Bearer " + memberDetails.authToken,
      "Content-Type": "application/json",
    },
  });
}
