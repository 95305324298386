import { Fragment } from "react";
import styled from "styled-components";
import { Grid, Typography } from "@mui/material";

import Banner1 from "../../assets/kyc banner.jpg";
import Banner2 from "../../assets/banner.avif";
import { Paper } from "../../components";
import KYC from "../../assets/kyc.jpg";

const KYCHeading = styled(Typography)`
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const HeadingSecond = styled(Typography)`
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  margin-bottom: 20px;
`;

const HeadingThird = styled(Typography)`
  color: #808080;
  font-size: 20px;
`;

const BannerKYC = styled.div`
  height: 400px;
  width: 100%;
`;

export default function Home() {
  return (
    <Fragment>
      <Paper padding={"40px 40px 0 40px"}>
        <Grid item xs={6}>
          <KYCHeading>KYC</KYCHeading>
          <HeadingSecond>
            {" "}
            A Seamless User Onboarding Experience, Helping You To Focus On Your
            Sales
          </HeadingSecond>

          <HeadingThird>
            We Offer Kyc Services That Come With Several Benefits to Both the
            Customers as Well as the Businesses. It is the Most Convenient Way
            to Quickly and Easily Verify Customer Information.
          </HeadingThird>
        </Grid>

        <Grid item xs={6}>
          <img src={KYC} alt="KYC" style={{ height: "500px", width: "100%" }} />
        </Grid>
      </Paper>

      <Grid container spacing={6}>
        <Grid item xs={6}>
          <Paper padding="20px">
            <BannerKYC>
              <img
                alt="Banner1"
                style={{
                  height: "inherit",
                  width: "inherit",
                  objectFit: "cover",
                }}
                src={Banner1}
              />
            </BannerKYC>
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper padding="20px">
            <BannerKYC>
              <img
                alt="Banner2"
                style={{
                  height: "inherit",
                  width: "inherit",
                  objectFit: "cover",
                }}
                src={Banner2}
              />
            </BannerKYC>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
}
