import React, { Fragment } from "react";

import { AiOutlineCreditCard } from "react-icons/ai";
import {
  BsFillCheckSquareFill,
  BsFillFileEarmarkExcelFill,
} from "react-icons/bs";

import { Card, CardContent, Typography, Grid } from "@mui/material";

export default function UsageDetails({ userData }) {
  return (
    <Fragment>
      {Object.keys(userData).map((item, index) => {
        return (
          <div key={index}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "600",
                margin: "10px",
              }}
              variant="h5"
            >
              Usage of{" "}
              {item.charAt(0).toUpperCase() + item.slice(1).replace(/_/g, " ")}
            </Typography>

            <Grid container spacing={2} direction="row">
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                sx={{
                  marginTop: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Card sx={{ width: "95%", height: "100%" }}>
                  <CardContent>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "700",
                        textAlign: "center",
                      }}
                    >
                      <AiOutlineCreditCard />
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "700",
                        textAlign: "center",
                      }}
                    >
                      {userData[item].limit}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      Total credit
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                sx={{
                  marginTop: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Card sx={{ width: "95%", height: "100%" }}>
                  <CardContent>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "700",
                        textAlign: "center",
                      }}
                    >
                      {userData[item].count}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      Usage
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                sx={{
                  marginTop: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Card sx={{ width: "95%", height: "100%" }}>
                  <CardContent>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "700",
                        textAlign: "center",
                        color: "green",
                      }}
                    >
                      <BsFillCheckSquareFill />
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "700",
                        textAlign: "center",
                      }}
                    >
                      {userData[item].success}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        textAlign: "center",
                        color: "green",
                      }}
                    >
                      Success
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                sx={{
                  marginTop: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Card sx={{ width: "95%", height: "100%" }}>
                  <CardContent>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "700",
                        textAlign: "center",
                        color: "red",
                      }}
                    >
                      <BsFillFileEarmarkExcelFill />
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "700",
                        textAlign: "center",
                      }}
                    >
                      {userData[item].fail}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        textAlign: "center",
                        color: "red",
                      }}
                    >
                      Failed
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        );
      })}
    </Fragment>
  );
}
