import React from "react";
import { makeStyles } from "@mui/styles";
import { Dialog as MUIDialog, DialogContent, Typography } from "@mui/material";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "400px" },
  div: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
  },
}));

export default function ShowFileResponse({
  dialogState,
  setDialogState,
  fileUpload,
}) {
  const classes = useStyles();

  const handleClose = () => {
    setDialogState(false);
  };

  return (
    <React.Fragment>
      <MUIDialog
        open={dialogState}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
      >
        <DialogContent>
          {fileUpload && (
            <div className={classes.div}>
              <Typography variant="h5" style={{ marginRight: "10px" }}>
                Doc Status:
              </Typography>
              <Typography variant="h5">
                {fileUpload.status?.replace(/-/g, " ")}
              </Typography>
            </div>
          )}
        </DialogContent>
      </MUIDialog>
    </React.Fragment>
  );
}
