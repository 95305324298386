import * as Yup from "yup";

export const passportObject = {
  countryCode: "",
  file: "",
  fileName: "",
};

export const validationSchema = Yup.object().shape({
  countryCode: Yup.string().required("This field is required."),
  file: Yup.string().required("This field is required."),
});
