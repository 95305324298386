import React from "react";
import { CSVLink } from "react-csv";
import { LoadingButton } from "@mui/lab";
import { Grid, Button } from "@mui/material";

import { ComponentHeader, Paper, TextField } from "../../components";
import VoterTableList from "./voter table components/VoterTableList";

export default function FormDisplay({
  formikProps,
  voterDetails,
  setVoterDetails,
}) {
  return (
    <React.Fragment>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader title={"Voter Details"} buttons={[<SampleFile />]} />
        </Grid>

        <Grid item xs={6}>
          <TextField
            required
            name="epicNumber"
            label="Voter Number"
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs></Grid>

        <Grid item alignSelf="center">
          <LoadingButton
            type="submit"
            variant="contained"
            fullWidth
            onClick={formikProps.handleSubmit}
            disabled={!formikProps.dirty || !formikProps.isValid}
            loading={formikProps.isSubmitting}
          >
            Fetch Voter Details
          </LoadingButton>
        </Grid>
      </Paper>

      <VoterTableList
        voterDetails={voterDetails}
        setVoterDetails={setVoterDetails}
      />
    </React.Fragment>
  );

  function SampleFile() {
    const csvData = [
      {
        ac_name: "CHHATARPUR",
        ac_name_v1: "छत्तर पुर",
        ac_no: "46",
        age: "28",
        ist_name_v1: "दक्षिण",
        district: "SOUTH",
        district_code: "3",
        dob: "NA",
        email_id: "",
        pic_no: "UBV2042711",
        gender: "M",
        house_no: "0",
        d: "0",
        ast_update: "Fri Nov 04 13:26:00 IST 2022",
        ob_no: "",
        ame: "Shubham Shrivastva",
        ame_v1: "शुभम श्रीवास्तव",
        ame_v2: "",
        ame_v3: "",
        art_name: "SATBARI",
        art_name_v1: "सतबडी",
        art_no: "83",
        c_name: "SOUTH DELHI",
        c_name_v1: "दक्षिणी दिल्ली",
        c_no: "7",
        s_lat_long: "0.0,0.0",
        s_lat_long_0_coordinate: "0",
        s_lat_long_1_coordinate: "0",
        s_name: "MC PRIMARY CO-ED SCHOOL SATBARI",
        s_name_v1: "एम.सी.प्राईमरी को-एड स्कुल सतबडी",
        s_no: "83",
        ln_name: "Praveen Srivastava",
        ln_name_v1: "प्रवीण श्रीवास्तव",
        ln_name_v2: "",
        ln_name_v3: "",
        ln_type: "F",
        ection_no: "1",
        lno_inpart: "548",
        t_code: "U05",
        state: "NCT OF Delhi",
        status: "N",
      },
    ];

    return (
      <Button variant="outlined">
        <CSVLink data={csvData} style={{ textDecoration: "none" }}>
          {" "}
          Download Sample File
        </CSVLink>
      </Button>
    );
  }
}
