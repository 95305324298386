import * as Yup from "yup";
import { Formik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";

import FormDisplay from "./FormDisplay";
import { useAlert, useAxios } from "../../../hooks";

export default function Students() {
  const axios = useAxios();

  const { alert } = useAlert();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
  });

  return (
    <Formik
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={(formData) => {
        setLoading(true);
        axios({
          url: "/students/verify-account/" + formData.email,
          method: "GET",
          disableRedirect: true,
          disableError: true,
        }).then((response) => {
          if (response.status) {
            setLoading(false);

            dispatch(
              alert({
                type: "success",
                message: response.message.displayMessage,
              })
            );
          } else {
            dispatch(
              alert({
                type: "error",
                message: response.message,
              })
            );

            setLoading(false);
          }
        });
      }}
    >
      {(formikProps) => (
        <FormDisplay formikProps={formikProps} loading={loading} />
      )}
    </Formik>
  );
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email is Required"),
});
