import React, { useState } from "react";

import Form from "./Form";
import { PageHeader } from "../../components";
import { passportObject } from "./passportObject";

export default function PassportDetails() {
  const [formData, setFormData] = useState(passportObject);
  const [PassportDetails, setPassportDetails] = useState({});

  return (
    <React.Fragment>
      <PageHeader pageName="Passport Details" />

      <Form
        formData={formData}
        setFormData={setFormData}
        PassportDetails={PassportDetails}
        setPassportDetails={setPassportDetails}
      />
    </React.Fragment>
  );
}
