import * as Yup from "yup";

export const aadharDetailsObject = {
  otp: "",
  uuid: "",
  captcha: "",
  aadharNumber: "",
};

export function objectAadharDetails(responseData) {
  return {
    uuid: responseData.uuid,
    captcha: responseData.captcha,
  };
}

export const validationSchema = Yup.object().shape({
  otp: Yup.string().required("This field is required."),
  captcha: Yup.string().required("This field is required"),
  aadharNumber: Yup.string().required("This field is required"),
});
