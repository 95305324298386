import React from "react";
import { Grid, Typography, Chip } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { Paper, ComponentHeader, TextField } from "../../components";

export default function FormDisplay({ formikProps, matchName }) {
  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader title={"Match"} />
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          name="firstName"
          label="Name 1"
          formikProps={formikProps}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          name="secondName"
          label="Name 2"
          formikProps={formikProps}
        />
      </Grid>
      <Grid item xs />
      <Grid item>
        <LoadingButton
          type="submit"
          variant="contained"
          fullWidth
          onClick={formikProps.handleSubmit}
          disabled={!formikProps.dirty || !formikProps.isValid}
          loading={formikProps.isSubmitting}
        >
          Match
        </LoadingButton>
      </Grid>

      <Grid item xs={12}>
        <Typography>
          Result: {!!matchName.data && matchName.data ? "Matched" : "Unmatched"}
        </Typography>
        {matchName.match_score >= 75 ? (
          <Typography style={{ color: "green" }}>
            Matched score : {matchName.match_score}%
          </Typography>
        ) : matchName.match_score >= 50 ? (
          <Typography style={{ color: "orange" }}>
            Matched score: {matchName.match_score}%
          </Typography>
        ) : (
          <Typography style={{ color: "red" }}>
            Matched score: {matchName.match_score}%
          </Typography>
        )}
      </Grid>
    </Paper>
  );
}
