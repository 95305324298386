import * as Yup from "yup";

export const passportObject = {
  dob: "",
  fileNumber: "",
};

export const validationSchema = Yup.object().shape({
  dob: Yup.string().required("This field is required."),
  fileNumber: Yup.string().required("This field is required."),
});
