import React from "react";
import DataTable from "./../../components/DataTable";
import { Button, Grid, Typography } from "@mui/material";

export default function Table({ rcDetails, setProcessStatus }) {
  const options = {
    sortOrder: {
      name: "created_at",
      direction: "desc",
      rowsPerPage: 50,
      rowsPerPageOptions: [100],
      filter: false,
      viewColumns: false,
    },
    print: false,
  };

  const columns = [
    {
      name: "pnr_no",
      label: "PNR Number",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "gender",
      label: "Gender",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "dob",
      label: "Date Of Birth",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "flight_no",
      label: "Flight Number",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "flight_date",
      label: "Flight Date",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "flight_date",
      label: "Select",
      options: {
        customBodyRender: (value) => {
          return value ? (
            <Button
              variant="contained"
              onClick={() =>
                setProcessStatus((prev) => ({
                  ...prev,
                  showPassengerDetails: true,
                }))
              }
            >
              Select
            </Button>
          ) : (
            "Not Available"
          );
        },
      },
    },
  ];

  return (
    <React.Fragment>
      <Grid item container>
        <Grid item xs={12} mb={4}>
          <Typography variant="h6" fontWeight={"bold"} textAlign={"center"}>
            Passengers Detail List
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <DataTable columns={columns} options={options} data={rcDetails} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
