import React from "react";
import { CSVLink } from "react-csv";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { PhotoCamera } from "@mui/icons-material";
import { Grid, IconButton, Button } from "@mui/material";

import { useComponent } from "../../hooks";
import OcrTableList from "./ocr table components/OcrTableList";
import { ComponentHeader, Paper, TextField } from "../../components";

export default function FormDisplay({
  formikProps,
  ocrDetails,
  setOcrDetails,
}) {
  const dispatch = useDispatch();

  const { alert } = useComponent();

  function handleFileChange(event) {
    const file = event.target.files[0];

    const fileSize = Math.round(file.size / 1024);

    if (fileSize > 2 * 1024) {
      dispatch(alert.error("File size too large! Make smaller (under 2 MB)"));
      return;
    }

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      fileName: file.name,
      file: file,
    }));
  }

  return (
    <React.Fragment>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader title={"Verify ID Student"} />
        </Grid>

        <Grid item xs={4}>
          <TextField
            required
            name="fileName"
            label="File"
            formikProps={formikProps}
            placeholder="No file chosen"
            InputProps={{
              readOnly: true,
              startAdornment: (
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  onChange={handleFileChange}
                >
                  <input
                    hidden
                    type="file"
                    accept=".jpg,.jpeg,.png,.gif"
                    onChange={handleFileChange}
                  />
                  <PhotoCamera />
                </IconButton>
              ),
            }}
          />
        </Grid>

        <Grid item xs></Grid>
        <Grid item alignSelf="center">
          <LoadingButton
            type="submit"
            variant="contained"
            fullWidth
            onClick={formikProps.handleSubmit}
            disabled={!formikProps.dirty || !formikProps.isValid}
            loading={formikProps.isSubmitting}
          >
            Fetch Ocr Details
          </LoadingButton>
        </Grid>
      </Paper>

      <OcrTableList ocrDetails={ocrDetails} setOcrDetails={setOcrDetails} />
    </React.Fragment>
  );

  function SampleFile() {
    const csvData = [
      {
        fileName: "file",
        ocType: null,
        ersonal: false,
        ame: "DEVADIGA",
        umber: "4467704",
        ehicleNumber: null,
        ctionDate: "2028-06-24",
        ate: "2018-06-25",
        iller: null,
        mount: null,
        ccountNumber: null,
        fscCode: null,
      },
    ];

    return (
      <Button variant="outlined">
        <CSVLink data={csvData} style={{ textDecoration: "none" }}>
          {" "}
          Download Sample File
        </CSVLink>
      </Button>
    );
  }
}
