import React from "react";

import { convertDate } from "../../utils";
import { Chip, DataTable } from "../../components";

export default function Table({ logs }) {
  const columns = [
    {
      name: "request_id",
      label: "Request ID",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "created_at",
      label: "Created At",
      options: {
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },

    {
      name: "api_name",
      label: "API Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "created_by",
      label: "Created By",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "ip",
      label: "IP Address",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "message",
      label: "Message",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "api_status",
      label: "API Status",
      options: {
        customBodyRender: (value) => {
          return value ? (
            value === "success" ? (
              <Chip type="success" label="Success" />
            ) : (
              <Chip type="error" label="Failed" />
            )
          ) : (
            "Not Available"
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    rowsPerPage: 25,
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    count: logs.total_count,
    rowsPerPageOptions: [5, 10, 15, 20, 25],

    sortOrder: {
      name: "log_timestamp",
      direction: "desc",
    },
  };

  return (
    <React.Fragment>
      <DataTable
        columns={columns}
        options={options}
        data={logs.data}
        title={` Logs`}
      />
    </React.Fragment>
  );
}
