import React, { useState } from "react";

import Form from "./Form";
import { PageHeader } from "../../components";
import { matchObject } from "./matchObject";

export default function Match() {
  const [formData, setFormData] = useState(matchObject);
  const [matchName, setMatchName] = useState({});

  return (
    <React.Fragment>
      <PageHeader pageName={"Match"} />

      <Form
        formData={formData}
        setFormData={setFormData}
        matchName={matchName}
        setMatchName={setMatchName}
      />
    </React.Fragment>
  );
}
