import { Formik } from "formik";
import { useDispatch } from "react-redux";

import FormDisplay from "./FormDisplay";
import { useAlert, useAxios } from "../../hooks";
import { validationSchema } from "./passportObject";

export default function Form({
  formData,
  PassportDetails,
  setPassportDetails,
}) {
  const axios = useAxios({
    baseURL: "passport",
    contentType: "multipart/form-data",
  });
  const { alert } = useAlert();
  const dispatch = useDispatch();

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        const data = new FormData();
        data.append("country_code", formData.countryCode);
        data.append("file", formData.file);
        axios({
          url: "/passport-details",
          method: "POST",
          data: data,
          disableRedirect: true,
        })
          .then(async (res) => {
            setSubmitting(false);
            if (res.status) {
              dispatch(alert({ message: "Details fetched!", type: "success" }));
              setPassportDetails(res.data.MRZdata);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>
          <FormDisplay
            formikProps={formikProps}
            PassportDetails={PassportDetails}
            setPassportDetails={setPassportDetails}
          />
        </form>
      )}
    </Formik>
  );
}
