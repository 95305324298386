import React from "react";
import { CSVLink } from "react-csv";
import { LoadingButton } from "@mui/lab";
import { PhotoCamera } from "@mui/icons-material";
import { Grid, IconButton, Button } from "@mui/material";

import { ComponentHeader, Paper, TextField } from "../../components";
import PassportTableList from "./passport table components/PassportTableList";

export default function FormDisplay({
  formikProps,
  PassportDetails,
  setPassportDetails,
}) {
  function handleFileChange(event) {
    const file = event.target.files[0];
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      fileName: file.name,
      file: file,
    }));
  }

  return (
    <React.Fragment>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader
            buttons={[<SampleFile />]}
            title={"Passport Details"}
            subtitle=""
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            required
            name="countryCode"
            label="Country Code"
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            required
            name="fileName"
            label="File"
            formikProps={formikProps}
            placeholder="No file chosen"
            InputProps={{
              readOnly: true,
              startAdornment: (
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  onChange={handleFileChange}
                >
                  <input
                    hidden
                    type="file"
                    accept=".jpg,.jpeg"
                    onChange={handleFileChange}
                  />
                  <PhotoCamera />
                </IconButton>
              ),
            }}
          />
        </Grid>

        <Grid item xs></Grid>
        <Grid item alignSelf="center">
          <LoadingButton
            type="submit"
            variant="contained"
            fullWidth
            onClick={formikProps.handleSubmit}
            disabled={!formikProps.dirty || !formikProps.isValid}
            loading={formikProps.isSubmitting}
          >
            Fetch Passport Details
          </LoadingButton>
        </Grid>
      </Paper>

      <PassportTableList
        PassportDetails={PassportDetails}
        setPassportDetails={setPassportDetails}
      />
    </React.Fragment>
  );

  function SampleFile() {
    const csvData = [
      {
        pan_number: "fgips5421l",
        pan_type: "Person",
        pan_status: "VALID",
        last_name: "SRIVASTAVA",
        first_name: "SHUBHAM",
        pan_holder_title: "Shri",
        pan_last_updated: "19/03/2022",
        name_on_card: "SHUBHAM SRIVASTAVA",
        aadhaar_seeding_status: "Y",
      },
    ];

    return (
      <Button variant="outlined">
        <CSVLink data={csvData} style={{ textDecoration: "none" }}>
          {" "}
          Download Sample File
        </CSVLink>
      </Button>
    );
  }
}
