import axios from "axios";
import BASE_URL from "../../../constants/baseURL";

export default async function signIn({ authToken, token }) {
  sessionStorage.setItem("authToken", authToken);
  sessionStorage.setItem("token", token);

  const axiosInstance = axios.create({
    baseURL: BASE_URL.passport.url + BASE_URL.passport.version,
    headers: {
      Authorization: "Bearer " + authToken,
      "Content-Type": "application/json",
    },
  });

  const permissions = await axiosInstance({
    url: "/admin/permissions",
    method: "GET",
  }).then((res) => res.data.data.page_permissions);

  return {
    authToken: authToken,
    token: token,
    permissions: permissions,
  };
}
