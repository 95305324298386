import React from "react";
import { CSVLink } from "react-csv";
import { LoadingButton } from "@mui/lab";
import { Event } from "@mui/icons-material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Grid, InputAdornment } from "@mui/material";

import { ComponentHeader, Paper, TextField } from "../../components";
import PassportTableList from "./passport table components/PassportTableList";

export default function FormDisplay({
  formikProps,
  PassportDetails,
  setPassportDetails,
}) {
  function handleDate(value) {
    formikProps.setValues((val) => ({
      ...val,
      dob: value,
    }));
  }

  return (
    <React.Fragment>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader title={"Fetch Passport  Details"} subtitle="" />
        </Grid>

        <Grid item xs={6}>
          <TextField
            required
            name="fileNumber"
            label="File Number"
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={6}>
          <MobileDatePicker
            name="dob"
            label="Date of birth"
            inputFormat="dd/MM/yyyy"
            value={formikProps.values.dob}
            onChange={(value) => handleDate(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                name={"dob"}
                value={formikProps.values.dob}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Event />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs></Grid>
        <Grid item alignSelf="center">
          <LoadingButton
            type="submit"
            variant="contained"
            fullWidth
            onClick={formikProps.handleSubmit}
            disabled={!formikProps.dirty || !formikProps.isValid}
            loading={formikProps.isSubmitting}
          >
            Fetch Passport Details
          </LoadingButton>
        </Grid>
      </Paper>

      <PassportTableList
        PassportDetails={PassportDetails}
        setPassportDetails={setPassportDetails}
      />
    </React.Fragment>
  );
}
