import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";

import Table from "./Table";
import FilterDataDialog from "./FilterDataDialog";
import { useAxios, useComponent } from "../../hooks";
import { Loader, PageHeader } from "../../components";

export default function Logs() {
  const axios = useAxios();
  const dispatch = useDispatch();
  const { loader } = useComponent();

  const [logs, setLogs] = useState([]);
  const [apiList, setApiList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  var [filterData, setFilterData] = useState({
    status: "success",
    start_time: "1673516458.050384",
    end_time: "1674205506.251739",
    api_name: "captcha_api",
    user_id: ["6bf71e0f8165412baadcbe1dde26dfcd"],
  });

  useEffect(() => {
    loader.start(1);
    axios({
      url: "/api-name",
      method: "GET",
    }).then((response) => {
      if (response.status) {
        setApiList(response.data);
        loader.apiComplete();
      }
    });
  }, [axios, loader]);

  useEffect(() => {
    loader.start(2);
    axios({
      url: "/members",
    })
      .then((response) => {
        if (response.status) {
          setMemberList(response.data);
          loader.apiComplete();
        }
      })
      .catch((err) => console.log(err));

    axios({
      url: "/logs-list",
      method: "POST",
      data: {},
    }).then((response) => {
      if (response.status) {
        setLogs(response);
        loader.apiComplete();
      }
    });
  }, [dispatch, axios, loader]);

  return (
    <Loader>
      <PageHeader pageName="Logs list" buttons={[<CreateButton />]} />

      <Table logs={logs} setLogs={setLogs} />

      <FilterDataDialog
        apiList={apiList}
        dialogOpen={dialogOpen}
        setApiList={setApiList}
        filterData={filterData}
        memberList={memberList}
        setFilterData={setFilterData}
        setDialogOpen={setDialogOpen}
        setMemberList={setMemberList}
      />
    </Loader>
  );

  function handleClickOpen() {
    setDialogOpen(true);
  }

  function CreateButton() {
    return (
      <Button color="primary" variant="contained" onClick={handleClickOpen}>
        Filter Logs
      </Button>
    );
  }
}
